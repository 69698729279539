import { Inject, Injectable, Injector } from '@angular/core';


import { Cartonization_cartonize_shipment_flowService } from './Cartonization.flow.index';
import { Cartonization_create_shipping_containers_for_shipment_flowService } from './Cartonization.flow.index';
import { Cartonization_get_eligible_container_types_flowService } from './Cartonization.flow.index';
import { Cartonization_get_shipment_quantities_to_cartonize_flowService } from './Cartonization.flow.index';
import { Cartonization_palletize_shipment_flowService } from './Cartonization.flow.index';
import { Cartonization_strategy_three_dimensional_fitting_flowService } from './Cartonization.flow.index';

import { $types } from './Cartonization.types'

@Injectable({ providedIn: 'root' })
export class Cartonization_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Cartonization: Cartonization_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _cartonize_shipment_flow: Cartonization_cartonize_shipment_flowService;
  public async cartonize_shipment_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._cartonize_shipment_flow) {
      this._cartonize_shipment_flow = this.injector.get(Cartonization_cartonize_shipment_flowService);
    }
    return this._cartonize_shipment_flow.run(inParams);
  }
  private _create_shipping_containers_for_shipment_flow: Cartonization_create_shipping_containers_for_shipment_flowService;
  public async create_shipping_containers_for_shipment_flow(inParams: { shipmentId: number, containers: { index?: number, parentIndex?: number, containerTypeId?: number, length?: number, width?: number, height?: number, dimensionsUomId?: number, volumeUomId?: number, weight?: number, weightUomId?: number, shipmentId?: number, shipmentLines?: { id?: number, takeBaseAmount?: number, tasks?: { id?: number, takeBaseAmount?: number }[] }[], inventoryMeasurementUnitId?: number, existingContainerIds?: number[] }[], runAsBatch?: boolean }): Promise< { batchEntities?: { method?: string, set?: string, keys?: { name?: string, value?: any }[], properties?: any }[], reasons?: string[] }> {
    if(!this._create_shipping_containers_for_shipment_flow) {
      this._create_shipping_containers_for_shipment_flow = this.injector.get(Cartonization_create_shipping_containers_for_shipment_flowService);
    }
    return this._create_shipping_containers_for_shipment_flow.run(inParams);
  }
  private _get_eligible_container_types_flow: Cartonization_get_eligible_container_types_flowService;
  public async get_eligible_container_types_flow(inParams: { shipmentId: number, context: string }): Promise< { containerTypeIds?: number[], logs?: string[] }> {
    if(!this._get_eligible_container_types_flow) {
      this._get_eligible_container_types_flow = this.injector.get(Cartonization_get_eligible_container_types_flowService);
    }
    return this._get_eligible_container_types_flow.run(inParams);
  }
  private _get_shipment_quantities_to_cartonize_flow: Cartonization_get_shipment_quantities_to_cartonize_flowService;
  public async get_shipment_quantities_to_cartonize_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[], quantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number, group?: string }[] }> {
    if(!this._get_shipment_quantities_to_cartonize_flow) {
      this._get_shipment_quantities_to_cartonize_flow = this.injector.get(Cartonization_get_shipment_quantities_to_cartonize_flowService);
    }
    return this._get_shipment_quantities_to_cartonize_flow.run(inParams);
  }
  private _palletize_shipment_flow: Cartonization_palletize_shipment_flowService;
  public async palletize_shipment_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._palletize_shipment_flow) {
      this._palletize_shipment_flow = this.injector.get(Cartonization_palletize_shipment_flowService);
    }
    return this._palletize_shipment_flow.run(inParams);
  }
  private _strategy_three_dimensional_fitting_flow: Cartonization_strategy_three_dimensional_fitting_flowService;
  public async strategy_three_dimensional_fitting_flow(inParams: { quantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number }[], containerTypeIds?: number[] }): Promise< { containers?: any[], uncartonizedQuantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number }[] }> {
    if(!this._strategy_three_dimensional_fitting_flow) {
      this._strategy_three_dimensional_fitting_flow = this.injector.get(Cartonization_strategy_three_dimensional_fitting_flowService);
    }
    return this._strategy_three_dimensional_fitting_flow.run(inParams);
  }
}
