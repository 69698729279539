import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_packing_slip_lines_archived_contentsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId?: number, shippingContainerId?: number }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_packing_slip_lines_archived_contents/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId?: number, shippingContainerId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_packing_slip_lines_archived_contents/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId?: number, shippingContainerId?: number, $keys: { LicensePlate?: { ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } } }[] }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_packing_slip_lines_archived_contents/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
