import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { PackVerification_homeComponent } from './PackVerification.home.component';
import { PackVerification_master_packing_list_reportComponent } from './PackVerification.master_packing_list_report.component';
import { PackVerification_packing_list_reportComponent } from './PackVerification.packing_list_report.component';
import { PackVerification_shipping_label_reportComponent } from './PackVerification.shipping_label_report.component';


@Injectable({ providedIn: 'root' })
export class PackVerification_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public PackVerification: PackVerification_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'PackVerification',
        referenceName: 'PackVerification_home',
        component: PackVerification_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PackVerification_homeComponent,
      'PackVerification',
      mode,
      dialogSize
    )
  }
  public openmaster_packing_list_report(inParams:{ shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'master_packing_list_report',
        referenceName: 'PackVerification_master_packing_list_report',
        component: PackVerification_master_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaster_packing_list_reportDialog(
    inParams:{ shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PackVerification_master_packing_list_reportComponent,
      'master_packing_list_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpacking_list_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'packing_list_report',
        referenceName: 'PackVerification_packing_list_report',
        component: PackVerification_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpacking_list_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PackVerification_packing_list_reportComponent,
      'packing_list_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_label_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'shipping_label_report',
        referenceName: 'PackVerification_shipping_label_report',
        component: PackVerification_shipping_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_label_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PackVerification_shipping_label_reportComponent,
      'shipping_label_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PackVerification_home') {
      const title = 'PackVerification';
      const component = PackVerification_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_master_packing_list_report') {
      const title = 'master_packing_list_report';
      const component = PackVerification_master_packing_list_reportComponent;
      const inParams:{ shipmentId: number } = { shipmentId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_packing_list_report') {
      const title = 'packing_list_report';
      const component = PackVerification_packing_list_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_shipping_label_report') {
      const title = 'shipping_label_report';
      const component = PackVerification_shipping_label_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
