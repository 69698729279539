import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Cartonization_OperationService } from './Cartonization.operation.service';
import { Returns_OperationService } from './Returns.operation.service';
import { WarehouseTransfers_OperationService } from './WarehouseTransfers.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { PackVerification_OperationService } from './PackVerification.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { InventoryTransfers_OperationService } from './InventoryTransfers.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.AsnOrders = this.injector.get(AsnOrders_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Cartonization = this.injector.get(Cartonization_OperationService);
    this.Returns = this.injector.get(Returns_OperationService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.PackVerification = this.injector.get(PackVerification_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public AsnOrders: AsnOrders_OperationService;
    public Attachments: Attachments_OperationService;
    public Cartonization: Cartonization_OperationService;
    public Returns: Returns_OperationService;
    public WarehouseTransfers: WarehouseTransfers_OperationService;
    public Notifications: Notifications_OperationService;
    public PackVerification: PackVerification_OperationService;
    public PrintNode: PrintNode_OperationService;
    public InventoryTransfers: InventoryTransfers_OperationService;
    public Reports: Reports_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
