import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild
} from '@angular/core';

import { DropdownSingleComponent } from './components/dropdown-single.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-home',
  templateUrl: './app.home.component.html'
})
export class app_homeComponent implements OnInit {

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  toolbar = {
  };


  fieldsets = {
  };

  @ViewChild('dd_config') dd_config: DropdownSingleComponent;
  @ViewChild('dd_method') dd_method: DropdownSingleComponent;

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) { 

  }

  ngOnInit(): void {
    this.$init();
  
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
    this.initialized = true;
  }

  close() {
    this.$finish.emit();
  }

  typeValue = null;
  typeList = [
    {
      key: 6,
      name: 'Datasource'
    },
    {
      key: 9,
      name: 'Function'
    }
  ];

  async typeDisplayWithFn(value: any): Promise<string> {
    return this.typeList.find(item => item.key === value)?.name;
  }

  async typeOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (this.utils.isDefinedTrimmed(filterText)) {
      return { list: this.typeList.filter(item => item.name.startsWith(filterText)), totalCount: this.typeList.length };
    } else {
      return { list: this.typeList, totalCount: this.typeList.length };
    }
  }

  typeValueChange(value) {
    this.typeValue = value;
    this.configValueChange(null);
    this.dd_config.clearListData();
  }


  configValue = null;
  config = null;
  configList = [
    {
      key: 'create_shipment_attachment',
      name: 'create_shipment_attachment',
      referenceName: 'create_shipment_attachment',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'file_content',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_shipping_container_attachment',
      name: 'create_shipping_container_attachment',
      referenceName: 'create_shipping_container_attachment',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'file_content',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_alerts_send',
      name: 'custom_alerts_send',
      referenceName: 'custom_alerts_send',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'log_level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'log_input',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'log_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_close_eligible_inventory_transfers_flow',
      name: 'custom_close_eligible_inventory_transfers_flow',
      referenceName: 'custom_close_eligible_inventory_transfers_flow',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'custom_crud_batch_request_flow',
      name: 'custom_crud_batch_request_flow',
      referenceName: 'custom_crud_batch_request_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entities',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'withTransaction',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_get_eligible_container_types_flow',
      name: 'custom_get_eligible_container_types_flow',
      referenceName: 'custom_get_eligible_container_types_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_get_report_data_flow',
      name: 'custom_get_report_data_flow',
      referenceName: 'custom_get_report_data_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'package_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'report_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_get_shipment_quantities_to_cartonize_flow',
      name: 'custom_get_shipment_quantities_to_cartonize_flow',
      referenceName: 'custom_get_shipment_quantities_to_cartonize_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_manual_allocation_automation',
      name: 'custom_manual_allocation_automation',
      referenceName: 'custom_manual_allocation_automation',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'custom_submit_hoplite_inbound_receipt',
      name: 'custom_submit_hoplite_inbound_receipt',
      referenceName: 'custom_submit_hoplite_inbound_receipt',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'print_cloud_report',
      name: 'print_cloud_report',
      referenceName: 'print_cloud_report',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'packageName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportInputs',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'printerName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_return_order_flow',
      name: 'process_return_order_flow',
      referenceName: 'process_return_order_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'transfer_licenseplates_to_new_salesorder_flow',
      name: 'transfer_licenseplates_to_new_salesorder_flow',
      referenceName: 'transfer_licenseplates_to_new_salesorder_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'custom_ds_get_manual_allocation_tasks_automation',
      name: 'custom_ds_get_manual_allocation_tasks_automation',
      referenceName: 'custom_ds_get_manual_allocation_tasks_automation',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_open_inventory_transfers',
      name: 'custom_ds_get_open_inventory_transfers',
      referenceName: 'custom_ds_get_open_inventory_transfers',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_order_by_order_id',
      name: 'custom_ds_get_order_by_order_id',
      referenceName: 'custom_ds_get_order_by_order_id',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_reasoncodes_by_parentId_parentEntity',
      name: 'custom_ds_get_reasoncodes_by_parentId_parentEntity',
      referenceName: 'custom_ds_get_reasoncodes_by_parentId_parentEntity',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_sales_order_tasks_by_orderId_linenumber',
      name: 'custom_ds_get_sales_order_tasks_by_orderId_linenumber',
      referenceName: 'custom_ds_get_sales_order_tasks_by_orderId_linenumber',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_by_shipmentId',
      name: 'ds_get_shipment_by_shipmentId',
      referenceName: 'ds_get_shipment_by_shipmentId',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'adjust_asn_orderline_and_shipmentline_flow',
      name: 'adjust_asn_orderline_and_shipmentline_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'adjust_asn_orderline_and_shipmentline_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'adjustPackagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentlineId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderlineNumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_move_asn_inventory_by_licenseplate_task_flow',
      name: 'auto_move_asn_inventory_by_licenseplate_task_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'auto_move_asn_inventory_by_licenseplate_task_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_receive_asn_orders',
      name: 'auto_receive_asn_orders' + ' ('+ 'AsnOrders' +')',
      referenceName: 'auto_receive_asn_orders',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'auto_receive_asn_task',
      name: 'auto_receive_asn_task' + ' ('+ 'AsnOrders' +')',
      referenceName: 'auto_receive_asn_task',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_asn_order_flow',
      name: 'cancel_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'cancel_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_receiving_task_flow',
      name: 'cancel_receiving_task_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'cancel_receiving_task_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_asn_order_flow',
      name: 'complete_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'complete_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_putaway_flow',
      name: 'complete_putaway_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'complete_putaway_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'copy_asn_order_udfs_to_new_order_flow',
      name: 'copy_asn_order_udfs_to_new_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'copy_asn_order_udfs_to_new_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'sourceOrderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetOrderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_asn_order_flow',
      name: 'create_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'create_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'orderClassId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_asn_order_line_in_processing_status_flow',
      name: 'create_asn_order_line_in_processing_status_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'create_asn_order_line_in_processing_status_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'grossWeight',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'weightUom',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_asn_serial_numbers_flow',
      name: 'create_asn_serial_numbers_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'create_asn_serial_numbers_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'serialNumbers',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_licenseplate_flow',
      name: 'create_licenseplate_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'create_licenseplate_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_shipment_for_asn_order_flow',
      name: 'create_shipment_for_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'create_shipment_for_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_asn_order_flow',
      name: 'delete_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'delete_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_asn_order_line_flow',
      name: 'delete_asn_order_line_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'delete_asn_order_line_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'linenumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_children_serial_numbers_flow',
      name: 'delete_children_serial_numbers_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'delete_children_serial_numbers_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'serialnumberIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'format_order_lookupcode_flow',
      name: 'format_order_lookupcode_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'format_order_lookupcode_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additionalData',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'format_order_lookupcode_flow_multiple',
      name: 'format_order_lookupcode_flow_multiple' + ' ('+ 'AsnOrders' +')',
      referenceName: 'format_order_lookupcode_flow_multiple',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'generate_asn_child_serial_numbers_flow',
      name: 'generate_asn_child_serial_numbers_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'generate_asn_child_serial_numbers_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'serialNumberIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_asn_order_state',
      name: 'get_asn_order_state' + ' ('+ 'AsnOrders' +')',
      referenceName: 'get_asn_order_state',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_license_plates_state_flow',
      name: 'get_license_plates_state_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'get_license_plates_state_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'license_plate_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_serials_to_delete_with_children_flow',
      name: 'get_serials_to_delete_with_children_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'get_serials_to_delete_with_children_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_auto_receivable_flow',
      name: 'is_asn_order_auto_receivable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_auto_receivable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_cancelable_flow',
      name: 'is_asn_order_cancelable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_cancelable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_completable_flow',
      name: 'is_asn_order_completable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_completable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_deletable_flow',
      name: 'is_asn_order_deletable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_deletable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_line_deletable_flow',
      name: 'is_asn_order_line_deletable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_line_deletable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'linenumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_processable_flow',
      name: 'is_asn_order_processable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_processable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_revertable_flow',
      name: 'is_asn_order_revertable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_revertable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'useValidation',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_unreceivable_flow',
      name: 'is_asn_order_unreceivable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_unreceivable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_validatable_flow',
      name: 'is_asn_order_validatable_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'is_asn_order_validatable_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'print_license_plate_labels_flow',
      name: 'print_license_plate_labels_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'print_license_plate_labels_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_asn_order_flow',
      name: 'process_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'process_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'revert_asn_order_flow',
      name: 'revert_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'revert_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'useValidation',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'set_asn_receiving_task_flow',
      name: 'set_asn_receiving_task_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'set_asn_receiving_task_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorlotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumbersToCreate',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'serialNumberIdsToDelete',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'existingTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'transfer_outstanding_asn_order_flow',
      name: 'transfer_outstanding_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'transfer_outstanding_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'createNewOrder',
            type: 'boolean',
            isCollection: false
          },
          {
            required: true,
            id: 'copyUdfs',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'unreceive_asn_order_flow',
      name: 'unreceive_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'unreceive_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'unreceive_licenseplate_asn_order_flow',
      name: 'unreceive_licenseplate_asn_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'unreceive_licenseplate_asn_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_asn_licenseplate_flow',
      name: 'update_asn_licenseplate_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'update_asn_licenseplate_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_order_flow',
      name: 'validate_order_flow' + ' ('+ 'AsnOrders' +')',
      referenceName: 'validate_order_flow',
      appReferenceName: 'AsnOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_asn_order_editor',
      name: 'ds_asn_order_editor' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_order_editor',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_asn_order_licenseplates_grid',
      name: 'ds_asn_order_licenseplates_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_order_licenseplates_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_asn_order_licenseplates_receiving_tasks_grid',
      name: 'ds_asn_order_licenseplates_receiving_tasks_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_order_licenseplates_receiving_tasks_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_asn_orderlines_grid',
      name: 'ds_asn_orderlines_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_orderlines_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_asn_orders_children_tasks_grid',
      name: 'ds_asn_orders_children_tasks_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_orders_children_tasks_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_asn_orders_tasks_grid',
      name: 'ds_asn_orders_tasks_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_asn_orders_tasks_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_children_serial',
      name: 'ds_find_children_serial' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_find_children_serial',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_licenseplates',
      name: 'ds_find_licenseplates' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_find_licenseplates',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_putaway_task_by_licenseplateId',
      name: 'ds_find_putaway_task_by_licenseplateId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_find_putaway_task_by_licenseplateId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_serialnumbers',
      name: 'ds_find_serialnumbers' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_find_serialnumbers',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId',
      name: 'ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'reasoncodeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'reasoncodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'reasoncodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_asn_licenseplate_count_by_shipmentId',
      name: 'ds_get_asn_licenseplate_count_by_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_asn_licenseplate_count_by_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_asn_orders_receiving_tasks',
      name: 'ds_get_asn_orders_receiving_tasks' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_asn_orders_receiving_tasks',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_asn_receiving_task',
      name: 'ds_get_asn_receiving_task' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_asn_receiving_task',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_asn_serial_numbers_to_delete',
      name: 'ds_get_asn_serial_numbers_to_delete' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_asn_serial_numbers_to_delete',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_inventorymove_tasks_by_licenseplateId',
      name: 'ds_get_completed_inventorymove_tasks_by_licenseplateId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_inventorymove_tasks_by_licenseplateId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId',
      name: 'ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId',
      name: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1',
      name: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      name: 'ds_get_completed_receiving_tasks_by_orderId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_tasks_by_orderId_top1',
      name: 'ds_get_completed_tasks_by_orderId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_completed_tasks_by_orderId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inbound_totals_by_linenumber_orderId',
      name: 'ds_get_inbound_totals_by_linenumber_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_inbound_totals_by_linenumber_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_count_tasks_by_licenseplateId_top1',
      name: 'ds_get_inventory_count_tasks_by_licenseplateId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_inventory_count_tasks_by_licenseplateId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventoryadjustment_tasks_by_chainhead',
      name: 'ds_get_inventoryadjustment_tasks_by_chainhead' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_inventoryadjustment_tasks_by_chainhead',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_license_plates_by_ids',
      name: 'ds_get_license_plates_by_ids' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_license_plates_by_ids',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'license_plate_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'license_plate_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'license_plate_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_licenseplateId',
      name: 'ds_get_licenseplate_by_licenseplateId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplate_by_licenseplateId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_licenseplateId',
      name: 'ds_get_licenseplatecontents_by_licenseplateId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplatecontents_by_licenseplateId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId',
      name: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_lookupcode_and_warehouseId',
      name: 'ds_get_licenseplates_by_lookupcode_and_warehouseId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplates_by_lookupcode_and_warehouseId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_orderId',
      name: 'ds_get_licenseplates_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplates_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shipmentId_or_ids',
      name: 'ds_get_licenseplates_by_shipmentId_or_ids' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_licenseplates_by_shipmentId_or_ids',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_location_by_locationId',
      name: 'ds_get_location_by_locationId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_location_by_locationId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_by_materialId',
      name: 'ds_get_material_by_materialId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_material_by_materialId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_and_packagingId',
      name: 'ds_get_material_packaging_by_materialId_and_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_and_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materialpackagings_by_materialIds',
      name: 'ds_get_materialpackagings_by_materialIds' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_materialpackagings_by_materialIds',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_account_address_by_orderId',
      name: 'ds_get_order_account_address_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_account_address_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_address_by_orderId_top1',
      name: 'ds_get_order_address_by_orderId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_address_by_orderId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId',
      name: 'ds_get_order_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId_detailed',
      name: 'ds_get_order_by_orderId_detailed' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_by_orderId_detailed',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_details_by_shipmentId',
      name: 'ds_get_order_details_by_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_details_by_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_licenseplates',
      name: 'ds_get_order_licenseplates' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_licenseplates',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_owner_contact_top1',
      name: 'ds_get_order_owner_contact_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_owner_contact_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_ship_from_account_vs_order_address',
      name: 'ds_get_order_ship_from_account_vs_order_address' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_ship_from_account_vs_order_address',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_validation_result_most_recent',
      name: 'ds_get_order_validation_result_most_recent' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_validation_result_most_recent',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_warehouse_contact_top1',
      name: 'ds_get_order_warehouse_contact_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_order_warehouse_contact_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderaddresses_by_orderId',
      name: 'ds_get_orderaddresses_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_orderaddresses_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_content',
      name: 'ds_get_orderlines_by_content' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_orderlines_by_content',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId',
      name: 'ds_get_orderlines_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_bottom1',
      name: 'ds_get_orderlines_by_orderId_bottom1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId_bottom1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_linenumber',
      name: 'ds_get_orderlines_by_orderId_linenumber' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId_linenumber',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_ordervalidationresults_by_orderId',
      name: 'ds_get_ordervalidationresults_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_ordervalidationresults_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_planned_asn_licenseplates_by_shipmentId',
      name: 'ds_get_planned_asn_licenseplates_by_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_planned_asn_licenseplates_by_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_planned_receiving_tasks_by_licenseplateId_top1',
      name: 'ds_get_planned_receiving_tasks_by_licenseplateId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_planned_receiving_tasks_by_licenseplateId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId',
      name: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1',
      name: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId',
      name: 'ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_putaway_tasks_by_licenseplateId_shipmentId',
      name: 'ds_get_putaway_tasks_by_licenseplateId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_putaway_tasks_by_licenseplateId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_putaway_tasks_by_shipmentId',
      name: 'ds_get_putaway_tasks_by_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_putaway_tasks_by_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_received_licenseplates_by_orderId_shipmentId',
      name: 'ds_get_received_licenseplates_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_received_licenseplates_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_receiving_tasks_by_orderId',
      name: 'ds_get_receiving_tasks_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_receiving_tasks_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_released_receiving_tasks_by_licenseplateId_top1',
      name: 'ds_get_released_receiving_tasks_by_licenseplateId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_released_receiving_tasks_by_licenseplateId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_released_receiving_tasks_by_orderId_shipmentId_top1',
      name: 'ds_get_released_receiving_tasks_by_orderId_shipmentId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_released_receiving_tasks_by_orderId_shipmentId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialcreation_task_by_serialnumberId',
      name: 'ds_get_serialcreation_task_by_serialnumberId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialcreation_task_by_serialnumberId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialnumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialcreation_tasks_by_chainhead',
      name: 'ds_get_serialcreation_tasks_by_chainhead' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialcreation_tasks_by_chainhead',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup',
      name: 'ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'actualTargetLicenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'actualTargetLicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'actualTargetLicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_serialNumberIds',
      name: 'ds_get_serialnumbers_by_serialNumberIds' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialnumbers_by_serialNumberIds',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialpurge_tasks_by_chainhead',
      name: 'ds_get_serialpurge_tasks_by_chainhead' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_serialpurge_tasks_by_chainhead',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_shipmentId',
      name: 'ds_get_shipmentLines_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_top1',
      name: 'ds_get_shipmentLines_by_orderId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'inProgress',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_shipmentId_top1',
      name: 'ds_get_shipmentLines_by_shipmentId_top1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_shipmentId_top1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId',
      name: 'ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentline_by_shipmentlineId',
      name: 'ds_get_shipmentline_by_shipmentlineId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentline_by_shipmentlineId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentlines_by_orderId_bottom1',
      name: 'ds_get_shipmentlines_by_orderId_bottom1' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentlines_by_orderId_bottom1',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentlines_by_orderline',
      name: 'ds_get_shipmentlines_by_orderline' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipmentlines_by_orderline',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_orderId',
      name: 'ds_get_shipments_by_orderId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipments_by_orderId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_shipmentId',
      name: 'ds_get_shipments_by_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_shipments_by_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_task_by_taskId',
      name: 'ds_get_task_by_taskId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_task_by_taskId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_chainHead',
      name: 'ds_get_tasks_by_chainHead' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_tasks_by_chainHead',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_orderId_shipmentId',
      name: 'ds_get_tasks_by_orderId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_tasks_by_orderId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_serialnumberIds',
      name: 'ds_get_tasks_by_serialnumberIds' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_tasks_by_serialnumberIds',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_validation_tasks_by_licenseplateId_shipmentId',
      name: 'ds_get_validation_tasks_by_licenseplateId_shipmentId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_validation_tasks_by_licenseplateId_shipmentId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_by_warehouseId',
      name: 'ds_get_warehouse_by_warehouseId' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_get_warehouse_by_warehouseId',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_license_plate_label_report',
      name: 'ds_license_plate_label_report' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_license_plate_label_report',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_license_plate_labels_by_order_report',
      name: 'ds_license_plate_labels_by_order_report' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_license_plate_labels_by_order_report',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_licenseplates_by_order_dd',
      name: 'ds_licenseplates_by_order_dd' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_licenseplates_by_order_dd',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderclasses_dd',
      name: 'ds_orderclasses_dd' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderclasses_dd',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_amount',
      name: 'ds_orderline_total_amount' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderline_total_amount',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_amount_widget',
      name: 'ds_orderline_total_amount_widget' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderline_total_amount_widget',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_gross_received_widget',
      name: 'ds_orderline_total_gross_received_widget' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderline_total_gross_received_widget',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_received',
      name: 'ds_orderline_total_received' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderline_total_received',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_received_widget',
      name: 'ds_orderline_total_received_widget' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orderline_total_received_widget',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orders_grid',
      name: 'ds_orders_grid' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_orders_grid',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_receiving_details_report',
      name: 'ds_receiving_details_report' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_receiving_details_report',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_receiving_header_report',
      name: 'ds_receiving_header_report' + ' ('+ 'AsnOrders' +')',
      referenceName: 'ds_receiving_header_report',
      appReferenceName: 'AsnOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'add_attachment_to_entity_flow',
      name: 'add_attachment_to_entity_flow' + ' ('+ 'Attachments' +')',
      referenceName: 'add_attachment_to_entity_flow',
      appReferenceName: 'Attachments',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entityKeys',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'attachmentDetails',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'fileContent',
            type: 'blob',
            isCollection: false
          },
          {
            required: true,
            id: 'entityType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_attachment_flow',
      name: 'delete_attachment_flow' + ' ('+ 'Attachments' +')',
      referenceName: 'delete_attachment_flow',
      appReferenceName: 'Attachments',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entityType',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'attachmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_file_content_flow',
      name: 'get_file_content_flow' + ' ('+ 'Attachments' +')',
      referenceName: 'get_file_content_flow',
      appReferenceName: 'Attachments',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entityType',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'attachmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_get_attachments_by_entity',
      name: 'ds_get_attachments_by_entity' + ' ('+ 'Attachments' +')',
      referenceName: 'ds_get_attachments_by_entity',
      appReferenceName: 'Attachments',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'entityType',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'entityKeys',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'entityKeys',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'cartonize_shipment_flow',
      name: 'cartonize_shipment_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'cartonize_shipment_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_shipping_containers_for_shipment_flow',
      name: 'create_shipping_containers_for_shipment_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'create_shipping_containers_for_shipment_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'containers',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'runAsBatch',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_eligible_container_types_flow',
      name: 'get_eligible_container_types_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'get_eligible_container_types_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_shipment_quantities_to_cartonize_flow',
      name: 'get_shipment_quantities_to_cartonize_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'get_shipment_quantities_to_cartonize_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'palletize_shipment_flow',
      name: 'palletize_shipment_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'palletize_shipment_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'strategy_three_dimensional_fitting_flow',
      name: 'strategy_three_dimensional_fitting_flow' + ' ('+ 'Cartonization' +')',
      referenceName: 'strategy_three_dimensional_fitting_flow',
      appReferenceName: 'Cartonization',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'quantities',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'containerTypeIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'ds_get_completed_picking_tasks_by_shipmentId',
      name: 'ds_get_completed_picking_tasks_by_shipmentId' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_completed_picking_tasks_by_shipmentId',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_container_types',
      name: 'ds_get_container_types' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_container_types',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialIds',
      name: 'ds_get_material_packaging_by_materialIds' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_material_packaging_by_materialIds',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_max_shipment_line_number_by_shipmentId',
      name: 'ds_get_max_shipment_line_number_by_shipmentId' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_max_shipment_line_number_by_shipmentId',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_picking_tasks_by_shipmentId',
      name: 'ds_get_picking_tasks_by_shipmentId' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_picking_tasks_by_shipmentId',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_lines_by_shipmentId',
      name: 'ds_get_shipment_lines_by_shipmentId' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_shipment_lines_by_shipmentId',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_containers_by_shipmentId',
      name: 'ds_get_shipping_containers_by_shipmentId' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_shipping_containers_by_shipmentId',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_containers_to_remove',
      name: 'ds_get_shipping_containers_to_remove' + ' ('+ 'Cartonization' +')',
      referenceName: 'ds_get_shipping_containers_to_remove',
      appReferenceName: 'Cartonization',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'alerts_for_entity_import_insert',
      name: 'alerts_for_entity_import_insert' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_for_entity_import_insert',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'request_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_for_entity_import_main',
      name: 'alerts_for_entity_import_main' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_for_entity_import_main',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'days',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_main',
      name: 'alerts_main' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_main',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'days',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'retry',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_send',
      name: 'alerts_send' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_send',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'log_level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'log_input',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'log_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_send_test',
      name: 'alerts_send_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_send_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'to',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_asn_order_flow',
      name: 'cancel_asn_order_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_order_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'status_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_asn_order_lines',
      name: 'cancel_asn_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_asn_orders',
      name: 'cancel_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_order_by_id',
      name: 'cancel_order_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_order_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_notes',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'order_type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_class_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_status_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'validate',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_purchase_order_lines',
      name: 'cancel_purchase_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_purchase_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_purchase_orders',
      name: 'cancel_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_sales_order_lines',
      name: 'cancel_sales_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_sales_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_sales_orders',
      name: 'cancel_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_accounts',
      name: 'create_accounts' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_accounts',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'accounts',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_asn_orders',
      name: 'create_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_carriers',
      name: 'create_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'carriers',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_instruction',
      name: 'create_instruction' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_instruction',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'instruction',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_material_packagings',
      name: 'create_material_packagings' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_material_packagings',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_packagings',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_materials',
      name: 'create_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_missing_shipments',
      name: 'create_missing_shipments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_missing_shipments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_purchase_orders',
      name: 'create_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_sales_orders',
      name: 'create_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_vendor_lots',
      name: 'create_vendor_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_vendor_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendor_lots',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'entity_import_parse_errors',
      name: 'entity_import_parse_errors' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'entity_import_parse_errors',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'request_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_move',
      name: 'file_transfer_move' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_move',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extenstion',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'source_folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'target_folder',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_pull',
      name: 'file_transfer_pull' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_pull',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extenstion',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'download',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_push',
      name: 'file_transfer_push' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_push',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'files',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'file_transfer_test',
      name: 'file_transfer_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'find_and_cancel_orders',
      name: 'find_and_cancel_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'find_and_cancel_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_class',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner_reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendor_reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_age_days',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footpring_generic_json_order_to_xml_order',
      name: 'footpring_generic_json_order_to_xml_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footpring_generic_json_order_to_xml_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'DatexOrderInfo',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_check_order_status',
      name: 'footprint_check_order_status' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_check_order_status',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'update',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_confirm_entity_import',
      name: 'footprint_confirm_entity_import' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_confirm_entity_import',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_export_data',
      name: 'footprint_generic_json_adjustment_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'renderLots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'renderLicensePlates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'renderSerialNumbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'hasTransmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taskIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'typeIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_export_flow',
      name: 'footprint_generic_json_adjustment_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modified_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modified_to',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'has_transmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'output_messages',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_import_flow',
      name: 'footprint_generic_json_adjustment_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'adjustments',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'submitAsync',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'environment',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sender',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'receiver',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_sample',
      name: 'footprint_generic_json_adjustment_sample' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_sample',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'footprint_generic_json_adjustment_schema',
      name: 'footprint_generic_json_adjustment_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_to_entity_import_flow',
      name: 'footprint_generic_json_adjustment_to_entity_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_to_entity_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'adjustment',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_export_data',
      name: 'footprint_generic_json_inventory_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'only_inventory',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_export_flow',
      name: 'footprint_generic_json_inventory_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_schema',
      name: 'footprint_generic_json_inventory_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_export_data',
      name: 'footprint_generic_json_invoice_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'invoice_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_export_flow',
      name: 'footprint_generic_json_invoice_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'invoice_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'invoice_lookup',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_schema',
      name: 'footprint_generic_json_invoice_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'map',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_material_import_flow',
      name: 'footprint_generic_json_material_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_material_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'materials',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_material_to_entity_import_xml_flow',
      name: 'footprint_generic_json_material_to_entity_import_xml_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_material_to_entity_import_xml_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_export_data',
      name: 'footprint_generic_json_order_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_export_flow',
      name: 'footprint_generic_json_order_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_lookup',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_import_flow',
      name: 'footprint_generic_json_order_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'submitAsync',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'environment',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sender',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'receiver',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_schema',
      name: 'footprint_generic_json_order_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'map',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_summary_data',
      name: 'footprint_generic_json_order_summary_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_summary_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_to_crud_batch_request_flow',
      name: 'footprint_generic_json_order_to_crud_batch_request_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_to_crud_batch_request_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_to_entity_import_flow',
      name: 'footprint_generic_json_order_to_entity_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_to_entity_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_shipment_contents',
      name: 'footprint_generic_json_shipment_contents' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_shipment_contents',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'typeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_serial_numbers',
      name: 'footprint_generic_serial_numbers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_serial_numbers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'packaged_amount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packaging_short_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'net_weight',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'gross_weight',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'weight_uom',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_json_entity_import_entity_schema',
      name: 'footprint_json_entity_import_entity_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_json_entity_import_entity_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_manage_wavelength_transmissions',
      name: 'footprint_manage_wavelength_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_manage_wavelength_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_direction',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_accounts',
      name: 'get_accounts' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_accounts',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_asn_orders',
      name: 'get_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_carriers',
      name: 'get_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_configuration',
      name: 'get_configuration' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_configuration',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'value',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'user_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypt',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_configuration_examples',
      name: 'get_configuration_examples' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_configuration_examples',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_date_from_footprint',
      name: 'get_date_from_footprint' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_date_from_footprint',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'get_inventory',
      name: 'get_inventory' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_inventory',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'aggregation_level',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_inventory_adjustments',
      name: 'get_inventory_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_inventory_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'get_materials',
      name: 'get_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_purchase_orders',
      name: 'get_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_reason_codes',
      name: 'get_reason_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_reason_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_report_data_flow',
      name: 'get_report_data_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_report_data_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'package_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'report_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_sales_orders',
      name: 'get_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_user_defined_field_names',
      name: 'get_user_defined_field_names' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_user_defined_field_names',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'EntityTypes',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_user_defined_field_values',
      name: 'get_user_defined_field_values' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_user_defined_field_values',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'UserDefinedFieldNames',
            type: 'string',
            isCollection: true
          },
          {
            required: false,
            id: 'EntityType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'EntityKeys',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_vendor_lots',
      name: 'get_vendor_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_vendor_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'insert_log',
      name: 'insert_log' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'insert_log',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'process',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'integrity_check_interface_subsets',
      name: 'integrity_check_interface_subsets' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'integrity_check_interface_subsets',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input_create_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_material_packaging',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_material_packaging',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_sales_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_purchase_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_asn_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_account',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_account',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_inventory',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_instruction',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'inventory_adjustment_query',
      name: 'inventory_adjustment_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'inventory_adjustment_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'hasTransmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'summary',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'inventory_snapshot_query',
      name: 'inventory_snapshot_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'inventory_snapshot_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_cancelable_flow',
      name: 'is_asn_order_cancelable_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'is_asn_order_cancelable_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'status_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'lifecycle_clean',
      name: 'lifecycle_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'lifecycle_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_integrations',
      name: 'list_integrations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_integrations',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_processes',
      name: 'list_processes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_processes',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'process',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_statuses',
      name: 'list_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_transaction_types',
      name: 'list_transaction_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_transaction_types',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_clean',
      name: 'logs_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'logs_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'levels_to_purge',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'lot_create',
      name: 'lot_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'lot_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'description',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'main_auto_email_execute_rules_flow',
      name: 'main_auto_email_execute_rules_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'main_auto_email_execute_rules_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'messages_clean',
      name: 'messages_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'messages_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'migrate_storage_to_utilities',
      name: 'migrate_storage_to_utilities' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'migrate_storage_to_utilities',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'owner_create',
      name: 'owner_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'owner_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'print_cloud_report',
      name: 'print_cloud_report' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'print_cloud_report',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'packageName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportInputs',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'printerName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'purchase_order_create',
      name: 'purchase_order_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'purchase_order_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'requestedDeliveryDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderLines',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'addresses',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'customFields',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'carrier',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'purchase_order_query',
      name: 'purchase_order_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'purchase_order_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_create',
      name: 'sales_order_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'requestedDeliveryDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderLines',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'addresses',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'customFields',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'carrier',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_query',
      name: 'sales_order_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'trackQueriedOrders',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_shipping_summary_query',
      name: 'sales_order_shipping_summary_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_shipping_summary_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'shippedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'shippedTo',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'sftp_pull',
      name: 'sftp_pull' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sftp_pull',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'connection',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'source_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'archive_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'error_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extension',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'file_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sort_asc',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'sftp_push',
      name: 'sftp_push' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sftp_push',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'connection',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'target_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transit_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'files',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_adjustments',
      name: 'storage_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'task_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_configurations',
      name: 'storage_configurations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_configurations',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_control',
      name: 'storage_control' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_control',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'option',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_lifecycle',
      name: 'storage_lifecycle' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_lifecycle',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'statuses',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_logs',
      name: 'storage_logs' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_logs',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_messages',
      name: 'storage_messages' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_messages',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_populate_date_number',
      name: 'storage_populate_date_number' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_populate_date_number',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'storage_string_encrypt',
      name: 'storage_string_encrypt' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_string_encrypt',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypted',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_test',
      name: 'storage_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'messages',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'submit_entity_import_json_request',
      name: 'submit_entity_import_json_request' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'submit_entity_import_json_request',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'entityImport',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'groupId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transactionGroupId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'submit_entity_import_xml_request',
      name: 'submit_entity_import_xml_request' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'submit_entity_import_xml_request',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'entityImportXml',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'groupId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transactionGroupId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'translate_material_from_footprint_to_public',
      name: 'translate_material_from_footprint_to_public' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_material_from_footprint_to_public',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'footprint_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'translate_material_from_public_to_footprint_flow',
      name: 'translate_material_from_public_to_footprint_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_material_from_public_to_footprint_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'public_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'translate_sales_order_from_public_to_footprint_flow',
      name: 'translate_sales_order_from_public_to_footprint_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_sales_order_from_public_to_footprint_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'public_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'unpick_licenseplate_flow',
      name: 'unpick_licenseplate_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'unpick_licenseplate_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_asn_order',
      name: 'update_asn_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_asn_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_carrier',
      name: 'update_carrier' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_carrier',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'carrier_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_last_wavelength_transmission',
      name: 'update_last_wavelength_transmission' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_last_wavelength_transmission',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_direction',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_material',
      name: 'update_material' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_material',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_material_packaging',
      name: 'update_material_packaging' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_material_packaging',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaging',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_purchase_order',
      name: 'update_purchase_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_purchase_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_sales_order',
      name: 'update_sales_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_sales_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_vendor_lot',
      name: 'update_vendor_lot' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_vendor_lot',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendor_lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_create_sales_order_request_flow',
      name: 'validate_create_sales_order_request_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'validate_create_sales_order_request_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'vendor_lot_create',
      name: 'vendor_lot_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'vendor_lot_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'description',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'expirationDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'manufactureDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'vat',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_base_order_editor',
      name: 'ds_base_order_editor' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_base_order_editor',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_base_order_lines_grid',
      name: 'ds_base_order_lines_grid' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_base_order_lines_grid',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_import_requests',
      name: 'ds_entity_import_requests' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_import_requests',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_import_requests_grid',
      name: 'ds_entity_import_requests_grid' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_import_requests_grid',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_user_defined_fields',
      name: 'ds_entity_user_defined_fields' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_user_defined_fields',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'EntityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_address',
      name: 'ds_find_address' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_address',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'Name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'LookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'AccountType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'City',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Country',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Line1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Line2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'PostalCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'State',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'AttentionOf',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'FirstName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'LastName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ReferenceCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ContactType',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_carriers',
      name: 'ds_find_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_invoices',
      name: 'ds_find_invoices' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_invoices',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_licenseplates',
      name: 'ds_find_licenseplates' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_licenseplates',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_lots',
      name: 'ds_find_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_material_packagings',
      name: 'ds_find_material_packagings' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_material_packagings',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_materials',
      name: 'ds_find_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_operation_codes',
      name: 'ds_find_operation_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_operation_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_address_types',
      name: 'ds_find_order_address_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_address_types',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_by_transaction_id',
      name: 'ds_find_order_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_class',
      name: 'ds_find_order_class' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_class',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_orders',
      name: 'ds_find_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_projects',
      name: 'ds_find_projects' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_projects',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_reason_codes',
      name: 'ds_find_reason_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_reason_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_serialnumbers',
      name: 'ds_find_serialnumbers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_serialnumbers',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_shipment_transmission_by_transaction_id',
      name: 'ds_find_shipment_transmission_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_shipment_transmission_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_task_transmission_by_transaction_id',
      name: 'ds_find_task_transmission_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_task_transmission_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_vendorlots',
      name: 'ds_find_vendorlots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_vendorlots',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_warehouses',
      name: 'ds_find_warehouses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_warehouses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_generic_order_addresses_schema',
      name: 'ds_generic_order_addresses_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_generic_order_addresses_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_addresses_by_entity_and_id',
      name: 'ds_get_addresses_by_entity_and_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_addresses_by_entity_and_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_adjustment_tasks',
      name: 'ds_get_adjustment_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_adjustment_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_records_by_invoice_line_ids',
      name: 'ds_get_billing_records_by_invoice_line_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_billing_records_by_invoice_line_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_carrier_by_id',
      name: 'ds_get_carrier_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_carrier_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      name: 'ds_get_completed_receiving_tasks_by_orderId_top1' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_shipments_without_transmissions',
      name: 'ds_get_completed_shipments_without_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_completed_shipments_without_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_currency_by_id',
      name: 'ds_get_currency_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_currency_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse',
      name: 'ds_get_first_warehouse' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_first_warehouse',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_inventory_by_license_plate_lot_by_project_id',
      name: 'ds_get_inventory_by_license_plate_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_inventory_by_license_plate_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_by_location_lot_by_project_id',
      name: 'ds_get_inventory_by_location_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_inventory_by_location_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_by_invoice_id',
      name: 'ds_get_invoice_by_invoice_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_invoice_by_invoice_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_lines_by_invoice_id',
      name: 'ds_get_invoice_lines_by_invoice_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_invoice_lines_by_invoice_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_latest_order_by_project',
      name: 'ds_get_latest_order_by_project' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_latest_order_by_project',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_license_plates_for_adjustments',
      name: 'ds_get_license_plates_for_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_license_plates_for_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_licenseplateId',
      name: 'ds_get_licenseplate_by_licenseplateId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_licenseplate_by_licenseplateId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_locations_by_locationIds',
      name: 'ds_get_locations_by_locationIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_locations_by_locationIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_lots_by_lotIds',
      name: 'ds_get_lots_by_lotIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_lots_by_lotIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_by_lookupcode_and_project_lookupcode',
      name: 'ds_get_material_by_lookupcode_and_project_lookupcode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_material_by_lookupcode_and_project_lookupcode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packagings_by_upc',
      name: 'ds_get_material_packagings_by_upc' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_material_packagings_by_upc',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_and_packages_by_project_id',
      name: 'ds_get_materials_and_packages_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_and_packages_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_by_materialCodes_projectId',
      name: 'ds_get_materials_by_materialCodes_projectId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_by_materialCodes_projectId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_with_inventory',
      name: 'ds_get_materials_with_inventory' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_with_inventory',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_measurement_units',
      name: 'ds_get_measurement_units' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_measurement_units',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_addresses_by_order_id',
      name: 'ds_get_order_addresses_by_order_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_addresses_by_order_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_licenseplate',
      name: 'ds_get_order_by_licenseplate' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_by_licenseplate',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_information_by_shipment_id',
      name: 'ds_get_order_information_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_information_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_line_information_by_order_id',
      name: 'ds_get_order_line_information_by_order_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_line_information_by_order_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_status_by_transaction_ids',
      name: 'ds_get_order_status_by_transaction_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_status_by_transaction_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId',
      name: 'ds_get_orderlines_by_orderId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orderlines_by_orderId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_by_ids',
      name: 'ds_get_orders_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orders_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_without_shipments',
      name: 'ds_get_orders_without_shipments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orders_without_shipments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_owner_by_ownerCode',
      name: 'ds_get_owner_by_ownerCode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_owner_by_ownerCode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'ownerCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pallet_transactions_by_shipment_id',
      name: 'ds_get_pallet_transactions_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_pallet_transactions_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id',
      name: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_project_and_owner_by_lookup_code',
      name: 'ds_get_project_and_owner_by_lookup_code' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_project_and_owner_by_lookup_code',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_projects_by_projectCode',
      name: 'ds_get_projects_by_projectCode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_projects_by_projectCode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_projects_by_project_and_owner',
      name: 'ds_get_projects_by_project_and_owner' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_projects_by_project_and_owner',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_receiving_tasks_by_shipment_id',
      name: 'ds_get_receiving_tasks_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_receiving_tasks_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_request_xml_by_requestId_editor',
      name: 'ds_get_request_xml_by_requestId_editor' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_request_xml_by_requestId_editor',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_sample_data_for_adjustment',
      name: 'ds_get_sample_data_for_adjustment' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_sample_data_for_adjustment',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_ids',
      name: 'ds_get_serial_numbers_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_project_id',
      name: 'ds_get_serial_numbers_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_shipment_id',
      name: 'ds_get_serial_numbers_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_shipmentId',
      name: 'ds_get_shipmentLines_by_orderId_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_contents_by_shipment_id',
      name: 'ds_get_shipment_contents_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipment_contents_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_ids',
      name: 'ds_get_shipments_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_orderId',
      name: 'ds_get_shipments_by_orderId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_orderId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_shipmentId',
      name: 'ds_get_shipments_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_source_license_plate_tasks_by_shipmentId',
      name: 'ds_get_source_license_plate_tasks_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_source_license_plate_tasks_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_status_change_tasks',
      name: 'ds_get_status_change_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_status_change_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tags',
      name: 'ds_get_tags' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tags',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_licenseplateId',
      name: 'ds_get_tasks_by_licenseplateId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tasks_by_licenseplateId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tax_schedule_by_id',
      name: 'ds_get_tax_schedule_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tax_schedule_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_order_transmissions',
      name: 'ds_get_wavelength_order_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_order_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_shipment_transmissions',
      name: 'ds_get_wavelength_shipment_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_shipment_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_transmission_type',
      name: 'ds_get_wavelength_transmission_type' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_transmission_type',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'name',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_work_orders_by_ids',
      name: 'ds_get_work_orders_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_work_orders_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_material_statuses',
      name: 'ds_material_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_material_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_message_staging',
      name: 'ds_message_staging' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_message_staging',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_order_serial_tasks_by_shipmentId',
      name: 'ds_order_serial_tasks_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_order_serial_tasks_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_adjustments',
      name: 'ds_storage_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'task_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'task_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_configurations',
      name: 'ds_storage_configurations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_configurations',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_control',
      name: 'ds_storage_control' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_control',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'current',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'current',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_control_partners_widget',
      name: 'ds_storage_control_partners_widget' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_control_partners_widget',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_integrations',
      name: 'ds_storage_integrations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_integrations',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'forDropDown',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'forDropDown',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_lifecycle',
      name: 'ds_storage_lifecycle' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_lifecycle',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statuses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statuses',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_logs',
      name: 'ds_storage_logs' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_logs',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'created_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'created_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_message_statuses_widget',
      name: 'ds_storage_message_statuses_widget' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_message_statuses_widget',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_messages',
      name: 'ds_storage_messages' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_messages',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modified_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'request_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modified_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'request_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_processes',
      name: 'ds_storage_processes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_processes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_statuses',
      name: 'ds_storage_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_transaction_types',
      name: 'ds_storage_transaction_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_transaction_types',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_transaction_report',
      name: 'ds_transaction_report' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_transaction_report',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'log_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'message_ids',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'cancel_inventory_transfers_flow',
      name: 'cancel_inventory_transfers_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'cancel_inventory_transfers_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventory_transfer_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_inventory_transfer_flow',
      name: 'create_inventory_transfer_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'create_inventory_transfer_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'sourceProjectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetProjectId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_inventory_transfer_lines_flow',
      name: 'create_inventory_transfer_lines_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'create_inventory_transfer_lines_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'delete_inventory_transfer_flow',
      name: 'delete_inventory_transfer_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'delete_inventory_transfer_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_inventory_transfer_line_flow',
      name: 'delete_inventory_transfer_line_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'delete_inventory_transfer_line_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_inventory_transfers_flow',
      name: 'delete_inventory_transfers_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'delete_inventory_transfers_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventory_transfer_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'execute_inventory_transfer_flow',
      name: 'execute_inventory_transfer_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'execute_inventory_transfer_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_inventory_transfer_lines_states',
      name: 'get_inventory_transfer_lines_states' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'get_inventory_transfer_lines_states',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferLineIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_inventory_transfers_states',
      name: 'get_inventory_transfers_states' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'get_inventory_transfers_states',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'update_inventory_transfer_line_flow',
      name: 'update_inventory_transfer_line_flow' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'update_inventory_transfer_line_flow',
      appReferenceName: 'InventoryTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inventoryTransferLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_get_inventory_by_licenseplate_lot_packaging',
      name: 'ds_get_inventory_by_licenseplate_lot_packaging' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_inventory_by_licenseplate_lot_packaging',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_transfer_by_inventoryTransferId',
      name: 'ds_get_inventory_transfer_by_inventoryTransferId' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_inventory_transfer_by_inventoryTransferId',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_transfer_by_inventoryTransferId_detailed',
      name: 'ds_get_inventory_transfer_by_inventoryTransferId_detailed' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_inventory_transfer_by_inventoryTransferId_detailed',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_transfer_lines_by_inventoryTransferLineIds',
      name: 'ds_get_inventory_transfer_lines_by_inventoryTransferLineIds' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_inventory_transfer_lines_by_inventoryTransferLineIds',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferLineIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferLineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferLineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_transfers_by_inventoryTransferIds',
      name: 'ds_get_inventory_transfers_by_inventoryTransferIds' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_inventory_transfers_by_inventoryTransferIds',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_matching_material_and_lot',
      name: 'ds_get_matching_material_and_lot' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_matching_material_and_lot',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'lotCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'lotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'lotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_packagingId',
      name: 'ds_get_material_packaging_by_materialId_packagingId' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_packagingId',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_by_materialIds',
      name: 'ds_get_materials_by_materialIds' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_get_materials_by_materialIds',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_inventory_measurement_units_dd',
      name: 'ds_inventory_measurement_units_dd' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_inventory_measurement_units_dd',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_inventory_transfer_editor',
      name: 'ds_inventory_transfer_editor' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_inventory_transfer_editor',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_inventory_transfer_lines_grid',
      name: 'ds_inventory_transfer_lines_grid' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_inventory_transfer_lines_grid',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'inventoryTransferId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_inventory_transfer_statuses_dd',
      name: 'ds_inventory_transfer_statuses_dd' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_inventory_transfer_statuses_dd',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_inventory_transfers_grid',
      name: 'ds_inventory_transfers_grid' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_inventory_transfers_grid',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouses',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'sourceProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'targetProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'warehouses',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'sourceProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'targetProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'warehouses',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'sourceProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'targetProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_projects_dd',
      name: 'ds_projects_dd' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_projects_dd',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_vendorlots_dd',
      name: 'ds_vendorlots_dd' + ' ('+ 'InventoryTransfers' +')',
      referenceName: 'ds_vendorlots_dd',
      appReferenceName: 'InventoryTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'add_auto_email_rule_attachment_flow',
      name: 'add_auto_email_rule_attachment_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'add_auto_email_rule_attachment_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'add_auto_email_rule_flow',
      name: 'add_auto_email_rule_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'add_auto_email_rule_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'ruleProperties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'add_email_request_attachment_flow',
      name: 'add_email_request_attachment_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'add_email_request_attachment_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'requestId',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'fileContent',
            type: 'blob',
            isCollection: false
          },
          {
            required: true,
            id: 'fileName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'fileType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'add_email_request_flow',
      name: 'add_email_request_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'add_email_request_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'request',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_find',
      name: 'alerts_find' + ' ('+ 'Notifications' +')',
      referenceName: 'alerts_find',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'level',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_email_execute_rule_flow',
      name: 'auto_email_execute_rule_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'auto_email_execute_rule_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_email_schedule_format_flow',
      name: 'auto_email_schedule_format_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'auto_email_schedule_format_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'schedule',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_email_schedule_parse_flow',
      name: 'auto_email_schedule_parse_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'auto_email_schedule_parse_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'scheduleString',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_email_request_flow',
      name: 'create_email_request_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'create_email_request_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'entityType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'entityId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'to',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'cc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'bcc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'subject',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'body',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'attachments',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_auto_email_rule_attachment_flow',
      name: 'delete_auto_email_rule_attachment_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'delete_auto_email_rule_attachment_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'attachmentId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_auto_email_rule_flow',
      name: 'delete_auto_email_rule_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'delete_auto_email_rule_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_email_request_flow',
      name: 'delete_email_request_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'delete_email_request_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'requestId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'email_request_send',
      name: 'email_request_send' + ' ('+ 'Notifications' +')',
      referenceName: 'email_request_send',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'requestId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'email_send',
      name: 'email_send' + ' ('+ 'Notifications' +')',
      referenceName: 'email_send',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'to',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'cc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'bcc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'subject',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'html',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'attachments',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'email_send_test',
      name: 'email_send_test' + ' ('+ 'Notifications' +')',
      referenceName: 'email_send_test',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'to',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'cc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'bcc',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'subject',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'html',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'attachments',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_auto_email_rule_requests_flow',
      name: 'get_auto_email_rule_requests_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'get_auto_email_rule_requests_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_blob_by_requestAttachmentId',
      name: 'get_blob_by_requestAttachmentId' + ' ('+ 'Notifications' +')',
      referenceName: 'get_blob_by_requestAttachmentId',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'requestAttachmentId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_footprint_attachment_content_flow',
      name: 'get_footprint_attachment_content_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'get_footprint_attachment_content_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entityType',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'attachmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_is_auto_emailing_initialized_flow',
      name: 'get_is_auto_emailing_initialized_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'get_is_auto_emailing_initialized_flow',
      appReferenceName: 'Notifications',
      type: 9,
    },
    {
      key: 'initialize_auto_emailing_flow',
      name: 'initialize_auto_emailing_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'initialize_auto_emailing_flow',
      appReferenceName: 'Notifications',
      type: 9,
    },
    {
      key: 'main_email_requests_send',
      name: 'main_email_requests_send' + ' ('+ 'Notifications' +')',
      referenceName: 'main_email_requests_send',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'parse_emails_flow',
      name: 'parse_emails_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'parse_emails_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'email_string',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_alerts',
      name: 'storage_alerts' + ' ('+ 'Notifications' +')',
      referenceName: 'storage_alerts',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_alerts_filters',
      name: 'storage_alerts_filters' + ' ('+ 'Notifications' +')',
      referenceName: 'storage_alerts_filters',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'search',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'alert_ids',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'update_auto_email_rule_attachment_flow',
      name: 'update_auto_email_rule_attachment_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'update_auto_email_rule_attachment_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'attachmentId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_auto_email_rule_flow',
      name: 'update_auto_email_rule_flow' + ' ('+ 'Notifications' +')',
      referenceName: 'update_auto_email_rule_flow',
      appReferenceName: 'Notifications',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'ruleId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ruleProperties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_auto_email_rule_contexts_dd',
      name: 'ds_auto_email_rule_contexts_dd' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_auto_email_rule_contexts_dd',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_auto_email_rule_editor',
      name: 'ds_auto_email_rule_editor' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_auto_email_rule_editor',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'rule_id',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_auto_email_rule_reports',
      name: 'ds_auto_email_rule_reports' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_auto_email_rule_reports',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ruleId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ruleId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_auto_email_rule_reports_dd',
      name: 'ds_auto_email_rule_reports_dd' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_auto_email_rule_reports_dd',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'ruleContext',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeReports',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ruleContext',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeReports',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_auto_email_rules',
      name: 'ds_auto_email_rules' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_auto_email_rules',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'context',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'context',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_email_request_attachments_grid',
      name: 'ds_email_request_attachments_grid' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_email_request_attachments_grid',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_email_requests_grid',
      name: 'ds_email_requests_grid' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_email_requests_grid',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'entityId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ruleId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'context',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'unsent_only',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'entityId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ruleId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'context',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'unsent_only',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_auto_email_eligible_invoices',
      name: 'ds_get_auto_email_eligible_invoices' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_auto_email_eligible_invoices',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_auto_email_eligible_orders',
      name: 'ds_get_auto_email_eligible_orders' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_auto_email_eligible_orders',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_auto_email_field_parameters_by_context',
      name: 'ds_get_auto_email_field_parameters_by_context' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_auto_email_field_parameters_by_context',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_orders',
      name: 'ds_get_completed_orders' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_completed_orders',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'order_type_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'order_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'order_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_entity_metadata',
      name: 'ds_get_entity_metadata' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_entity_metadata',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'EntityType',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'EntityKeys',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'EntityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'EntityKeys',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_exported_invoices',
      name: 'ds_get_exported_invoices' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_exported_invoices',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'from_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_attachments',
      name: 'ds_get_invoice_attachments' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_invoice_attachments',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoices_by_invoiceIds',
      name: 'ds_get_invoices_by_invoiceIds' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_invoices_by_invoiceIds',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_attachments',
      name: 'ds_get_order_attachments' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_order_attachments',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_by_orderIds',
      name: 'ds_get_orders_by_orderIds' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_orders_by_orderIds',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_projects',
      name: 'ds_get_projects' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_projects',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_referenced_packages',
      name: 'ds_get_referenced_packages' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_referenced_packages',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses',
      name: 'ds_get_warehouses' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_get_warehouses',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_owners_dd',
      name: 'ds_owners_dd' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_owners_dd',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_projects_dd',
      name: 'ds_projects_dd' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_projects_dd',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_report_inputs_grid',
      name: 'ds_report_inputs_grid' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_report_inputs_grid',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'reportName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'inputs',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'reportName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'inputs',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_alerts',
      name: 'ds_storage_alerts' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_storage_alerts',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'alert_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'enabled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'alert_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'enabled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_alerts_filters',
      name: 'ds_storage_alerts_filters' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_storage_alerts_filters',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'alert_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'alert_id',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_time_selector_dd',
      name: 'ds_time_selector_dd' + ' ('+ 'Notifications' +')',
      referenceName: 'ds_time_selector_dd',
      appReferenceName: 'Notifications',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'intervalMinutes',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'intervalMinutes',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'begin_pack_verification_flow',
      name: 'begin_pack_verification_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'begin_pack_verification_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'canUseExisting',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_verification_tasks_flow',
      name: 'cancel_verification_tasks_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'cancel_verification_tasks_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'start_verification_task_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'license_plate_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaging_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'quantity',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serial_number_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'complete_pack_verification_flow',
      name: 'complete_pack_verification_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'complete_pack_verification_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'startPackVerificationTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'actualWeight',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'fail_pack_verification_flow',
      name: 'fail_pack_verification_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'fail_pack_verification_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'startPackVerificationTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_configurations_flow',
      name: 'get_configurations_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'get_configurations_flow',
      appReferenceName: 'PackVerification',
      type: 9,
    },
    {
      key: 'get_failure_location_flow',
      name: 'get_failure_location_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'get_failure_location_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_failure_reasoncode_flow',
      name: 'get_failure_reasoncode_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'get_failure_reasoncode_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_serial_number_substitution_reason_code_flow',
      name: 'get_serial_number_substitution_reason_code_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'get_serial_number_substitution_reason_code_flow',
      appReferenceName: 'PackVerification',
      type: 9,
    },
    {
      key: 'initialize_pack_verification_flow',
      name: 'initialize_pack_verification_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'initialize_pack_verification_flow',
      appReferenceName: 'PackVerification',
      type: 9,
    },
    {
      key: 'is_pack_verification_location_valid_flow',
      name: 'is_pack_verification_location_valid_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'is_pack_verification_location_valid_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'move_shipping_container_flow',
      name: 'move_shipping_container_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'move_shipping_container_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'save_shipping_container_actual_weight_flow',
      name: 'save_shipping_container_actual_weight_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'save_shipping_container_actual_weight_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'actualWeight',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'substitute_container_serial_numbers_flow',
      name: 'substitute_container_serial_numbers_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'substitute_container_serial_numbers_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'startVerificationTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'unpick_unverified_shipping_container_content_flow',
      name: 'unpick_unverified_shipping_container_content_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'unpick_unverified_shipping_container_content_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'startEndTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLicensePlateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_shipping_container_contents_flow',
      name: 'validate_shipping_container_contents_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'validate_shipping_container_contents_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'startPackVerificationTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'canSubstituteSerials',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_shipping_container_properties_flow',
      name: 'validate_shipping_container_properties_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'validate_shipping_container_properties_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'startPackVerificationTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additionalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'verify_content_flow',
      name: 'verify_content_flow' + ' ('+ 'PackVerification' +')',
      referenceName: 'verify_content_flow',
      appReferenceName: 'PackVerification',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'inputScan',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'inputManual',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'startPackVerificationTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'canSubstituteSerials',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_containertypes_dd',
      name: 'ds_containertypes_dd' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_containertypes_dd',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_pack_verification_task_by_serialNumberId',
      name: 'ds_get_completed_pack_verification_task_by_serialNumberId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_completed_pack_verification_task_by_serialNumberId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_serialnumbermove_task_by_serialNumberId',
      name: 'ds_get_completed_serialnumbermove_task_by_serialNumberId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_completed_serialnumbermove_task_by_serialNumberId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_container_type_by_containerTypeId',
      name: 'ds_get_container_type_by_containerTypeId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_container_type_by_containerTypeId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'containerTypeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_failed_verification_reasoncodes_by_shippingContainerIds',
      name: 'ds_get_failed_verification_reasoncodes_by_shippingContainerIds' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_failed_verification_reasoncodes_by_shippingContainerIds',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_instructions_by_shippingContainerId',
      name: 'ds_get_instructions_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_instructions_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'excludeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierServiceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'excludeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierServiceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'excludeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierServiceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'containerTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_shippingContainerId',
      name: 'ds_get_licenseplatecontents_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_licenseplatecontents_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shippingContainerId',
      name: 'ds_get_licenseplates_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_licenseplates_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_location_by_locationId',
      name: 'ds_get_location_by_locationId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_location_by_locationId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_lookup_by_materialId_packagingId',
      name: 'ds_get_material_packaging_lookup_by_materialId_packagingId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_material_packaging_lookup_by_materialId_packagingId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_open_start_end_pack_verification_task_by_shippingContainerId',
      name: 'ds_get_open_start_end_pack_verification_task_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_open_start_end_pack_verification_task_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_operationcode_by_operationCodeName_top1',
      name: 'ds_get_operationcode_by_operationCodeName_top1' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_operationcode_by_operationCodeName_top1',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'operationCodeName',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pack_verification_tasks_by_content',
      name: 'ds_get_pack_verification_tasks_by_content' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_pack_verification_tasks_by_content',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pack_verification_tasks_by_shippingContainerId',
      name: 'ds_get_pack_verification_tasks_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_pack_verification_tasks_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pick_slip_by_pickSlipId',
      name: 'ds_get_pick_slip_by_pickSlipId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_pick_slip_by_pickSlipId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'pickSlipId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_picking_tasks_by_licenseplateId',
      name: 'ds_get_picking_tasks_by_licenseplateId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_picking_tasks_by_licenseplateId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_reasoncode_by_reasonCodeId',
      name: 'ds_get_reasoncode_by_reasonCodeId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_reasoncode_by_reasonCodeId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'reasonCodeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_serialNumberCode',
      name: 'ds_get_serialnumber_by_serialNumberCode' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_serialnumber_by_serialNumberCode',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_serialNumberId',
      name: 'ds_get_serialnumber_by_serialNumberId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_serialnumber_by_serialNumberId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_shippingContainerId_serialNumberCode',
      name: 'ds_get_serialnumber_by_shippingContainerId_serialNumberCode' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_serialnumber_by_shippingContainerId_serialNumberCode',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_shippingContainerId',
      name: 'ds_get_serialnumbers_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_serialnumbers_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_containers_by_shipmentId',
      name: 'ds_get_shipping_containers_by_shipmentId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shipping_containers_by_shipmentId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_containers_not_verified_by_shipmentId',
      name: 'ds_get_shipping_containers_not_verified_by_shipmentId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shipping_containers_not_verified_by_shipmentId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainer_by_shippingContainerCode',
      name: 'ds_get_shippingcontainer_by_shippingContainerCode' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontainer_by_shippingContainerCode',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainer_by_shippingContainerId',
      name: 'ds_get_shippingcontainer_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontainer_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainer_content',
      name: 'ds_get_shippingcontainer_content' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontainer_content',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainer_instructions',
      name: 'ds_get_shippingcontainer_instructions' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontainer_instructions',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainers_by_warehouseId',
      name: 'ds_get_shippingcontainers_by_warehouseId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontainers_by_warehouseId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_shippingcontents_by_licenseplateId_lotId_packagingId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_shippingcontents_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_start_pack_verification_task_by_taskId',
      name: 'ds_get_start_pack_verification_task_by_taskId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_start_pack_verification_task_by_taskId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_start_pack_verification_tasks_by_shippingContainerId',
      name: 'ds_get_start_pack_verification_tasks_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_start_pack_verification_tasks_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_task_by_taskId',
      name: 'ds_get_task_by_taskId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_task_by_taskId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_unassigned_pack_verification_tasks_by_shippingContainerId',
      name: 'ds_get_unassigned_pack_verification_tasks_by_shippingContainerId' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_unassigned_pack_verification_tasks_by_shippingContainerId',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_upc_by_materal_ids',
      name: 'ds_get_upc_by_materal_ids' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_get_upc_by_materal_ids',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'material_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'material_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'material_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_measurementunits_dd',
      name: 'ds_measurementunits_dd' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_measurementunits_dd',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_pack_verification_shipping_container_contents_grid',
      name: 'ds_pack_verification_shipping_container_contents_grid' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_pack_verification_shipping_container_contents_grid',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_pack_verification_verify_container_editor',
      name: 'ds_pack_verification_verify_container_editor' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_pack_verification_verify_container_editor',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_reasoncodes_dd',
      name: 'ds_reasoncodes_dd' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_reasoncodes_dd',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_scanned_values_grid',
      name: 'ds_scanned_values_grid' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_scanned_values_grid',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'scannedValues',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'scannedValues',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipping_container_pack_verification_tasks_grid',
      name: 'ds_shipping_container_pack_verification_tasks_grid' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_shipping_container_pack_verification_tasks_grid',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shippingcontainers_failed_verification_grid',
      name: 'ds_shippingcontainers_failed_verification_grid' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_shippingcontainers_failed_verification_grid',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shippingcontainers_failed_verification_widget',
      name: 'ds_shippingcontainers_failed_verification_widget' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_shippingcontainers_failed_verification_widget',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shippingcontainers_to_verify_grid',
      name: 'ds_shippingcontainers_to_verify_grid' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_shippingcontainers_to_verify_grid',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'isFailed',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'isFailed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'isFailed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shippingcontainers_to_verify_widget',
      name: 'ds_shippingcontainers_to_verify_widget' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_shippingcontainers_to_verify_widget',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_verification_locations_dd',
      name: 'ds_verification_locations_dd' + ' ('+ 'PackVerification' +')',
      referenceName: 'ds_verification_locations_dd',
      appReferenceName: 'PackVerification',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'print_request_flow',
      name: 'print_request_flow' + ' ('+ 'PrintNode' +')',
      referenceName: 'print_request_flow',
      appReferenceName: 'PrintNode',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'printerId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'content',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'contentType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_credentials_flow',
      name: 'validate_credentials_flow' + ' ('+ 'PrintNode' +')',
      referenceName: 'validate_credentials_flow',
      appReferenceName: 'PrintNode',
      type: 9,
    },
    {
      key: 'ds_get_printers',
      name: 'ds_get_printers' + ' ('+ 'PrintNode' +')',
      referenceName: 'ds_get_printers',
      appReferenceName: 'PrintNode',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'letterOnly',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'letterOnly',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_printjobs',
      name: 'ds_get_printjobs' + ' ('+ 'PrintNode' +')',
      referenceName: 'ds_get_printjobs',
      appReferenceName: 'PrintNode',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'printJobId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'printerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'printJobId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'printerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_custom_receiving_report_dataLoggers',
      name: 'custom_ds_custom_receiving_report_dataLoggers' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_custom_receiving_report_dataLoggers',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_dock_appointment_details_report',
      name: 'custom_ds_dock_appointment_details_report' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_dock_appointment_details_report',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'dockAppointmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'dockAppointmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'dockAppointmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_dock_appointment_by_orderId',
      name: 'custom_ds_get_dock_appointment_by_orderId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_get_dock_appointment_by_orderId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_order_account_address_by_orderId',
      name: 'custom_ds_get_order_account_address_by_orderId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_get_order_account_address_by_orderId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_order_address_by_orderId_top1',
      name: 'custom_ds_get_order_address_by_orderId_top1' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_get_order_address_by_orderId_top1',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_order_ship_to_account_vs_order_address',
      name: 'custom_ds_get_order_ship_to_account_vs_order_address' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_get_order_ship_to_account_vs_order_address',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_get_shipping_containers_tasks',
      name: 'custom_ds_get_shipping_containers_tasks' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_get_shipping_containers_tasks',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'actualTargetShippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_licenseplate_details_by_serialId',
      name: 'custom_ds_licenseplate_details_by_serialId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_licenseplate_details_by_serialId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_label_by_shippingContainerId',
      name: 'custom_ds_outbound_label_by_shippingContainerId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_label_by_shippingContainerId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_get_order_address_by_orderId_name',
      name: 'custom_ds_outbound_packing_slip_get_order_address_by_orderId_name' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_get_order_address_by_orderId_name',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeName',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_get_project_address_by_projectId',
      name: 'custom_ds_outbound_packing_slip_get_project_address_by_projectId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_get_project_address_by_projectId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId',
      name: 'custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_header_by_shipmentId',
      name: 'custom_ds_outbound_packing_slip_header_by_shipmentId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_header_by_shipmentId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_header_by_shippingContainerId',
      name: 'custom_ds_outbound_packing_slip_header_by_shippingContainerId' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_header_by_shippingContainerId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_lines_archived_contents',
      name: 'custom_ds_outbound_packing_slip_lines_archived_contents' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_lines_archived_contents',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_lines_contents',
      name: 'custom_ds_outbound_packing_slip_lines_contents' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_lines_contents',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_lines_merged',
      name: 'custom_ds_outbound_packing_slip_lines_merged' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_lines_merged',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_outbound_packing_slip_report_order_lookup',
      name: 'custom_ds_outbound_packing_slip_report_order_lookup' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_outbound_packing_slip_report_order_lookup',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipment_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_receiving_details_report',
      name: 'custom_ds_receiving_details_report' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_receiving_details_report',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'custom_ds_receiving_header_report',
      name: 'custom_ds_receiving_header_report' + ' ('+ 'Reports' +')',
      referenceName: 'custom_ds_receiving_header_report',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_Outbound_Labels',
      name: 'ds_Outbound_Labels' + ' ('+ 'Reports' +')',
      referenceName: 'ds_Outbound_Labels',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'LookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inbound_totals_by_linenumber_orderId',
      name: 'ds_get_inbound_totals_by_linenumber_orderId' + ' ('+ 'Reports' +')',
      referenceName: 'ds_get_inbound_totals_by_linenumber_orderId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_account_address_by_orderId',
      name: 'ds_get_order_account_address_by_orderId' + ' ('+ 'Reports' +')',
      referenceName: 'ds_get_order_account_address_by_orderId',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_project_contact_top1',
      name: 'ds_get_order_project_contact_top1' + ' ('+ 'Reports' +')',
      referenceName: 'ds_get_order_project_contact_top1',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_warehouse_contact_top1',
      name: 'ds_get_order_warehouse_contact_top1' + ' ('+ 'Reports' +')',
      referenceName: 'ds_get_order_warehouse_contact_top1',
      appReferenceName: 'Reports',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'add_licenseplates_from_outbound_to_inbound_order_flow',
      name: 'add_licenseplates_from_outbound_to_inbound_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'add_licenseplates_from_outbound_to_inbound_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_receive_return_order_flow',
      name: 'auto_receive_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'auto_receive_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'noCommit',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_return_order_flow',
      name: 'cancel_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'cancel_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_return_order_flow',
      name: 'complete_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'complete_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'confirm_blind_container_flow',
      name: 'confirm_blind_container_flow' + ' ('+ 'Returns' +')',
      referenceName: 'confirm_blind_container_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'isAutoReceive',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_blind_return_order_flow',
      name: 'create_blind_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'create_blind_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'waitStatus',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'orderClassId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_blind_return_session_task_flow',
      name: 'create_blind_return_session_task_flow' + ' ('+ 'Returns' +')',
      referenceName: 'create_blind_return_session_task_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_licenseplate_flow',
      name: 'create_licenseplate_flow' + ' ('+ 'Returns' +')',
      referenceName: 'create_licenseplate_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_or_update_return_task_flow',
      name: 'create_or_update_return_task_flow' + ' ('+ 'Returns' +')',
      referenceName: 'create_or_update_return_task_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'chainHead',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'otherProperties',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'sourceLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumberId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'sourceShippingContainerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_return_order_from_outbound_flow',
      name: 'create_return_order_from_outbound_flow' + ' ('+ 'Returns' +')',
      referenceName: 'create_return_order_from_outbound_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'outboundOrderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'inboundOrder',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_return_order_flow',
      name: 'delete_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'delete_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_return_task_flow',
      name: 'delete_return_task_flow' + ' ('+ 'Returns' +')',
      referenceName: 'delete_return_task_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'excel_export_flow',
      name: 'excel_export_flow' + ' ('+ 'Returns' +')',
      referenceName: 'excel_export_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'ownerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouseIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'statusIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'fromDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'toDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceTypeId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'materialIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'fullTextSearch',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'exportLimit',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'exportType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'dateType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'dateFormat',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'totalRecords',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_blind_return_lot_flow',
      name: 'get_blind_return_lot_flow' + ' ('+ 'Returns' +')',
      referenceName: 'get_blind_return_lot_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorLotCode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'expirationDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'shippingContainerId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_blind_return_new_licenseplate_status',
      name: 'get_blind_return_new_licenseplate_status' + ' ('+ 'Returns' +')',
      referenceName: 'get_blind_return_new_licenseplate_status',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'manufactureDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'expirationDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'otherProperties',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'lotStatusId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_blind_return_new_lot_status',
      name: 'get_blind_return_new_lot_status' + ' ('+ 'Returns' +')',
      referenceName: 'get_blind_return_new_lot_status',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'manufactureDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'expirationDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_return_orders_state_flow',
      name: 'get_return_orders_state_flow' + ' ('+ 'Returns' +')',
      referenceName: 'get_return_orders_state_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'process_return_order_flow',
      name: 'process_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'process_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'revert_return_order_flow',
      name: 'revert_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'revert_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'unreceive_return_order_flow',
      name: 'unreceive_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'unreceive_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_return_order_flow',
      name: 'validate_return_order_flow' + ' ('+ 'Returns' +')',
      referenceName: 'validate_return_order_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_rga_number_flow',
      name: 'validate_rga_number_flow' + ' ('+ 'Returns' +')',
      referenceName: 'validate_rga_number_flow',
      appReferenceName: 'Returns',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'rgaNumber',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'otherProperties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_blind_shipping_container_return_tasks_grid',
      name: 'ds_blind_shipping_container_return_tasks_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_blind_shipping_container_return_tasks_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'taskStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'taskStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'taskStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_customer_return_shipping_containers_grid',
      name: 'ds_customer_return_shipping_containers_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_customer_return_shipping_containers_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_licenseplates',
      name: 'ds_find_licenseplates' + ' ('+ 'Returns' +')',
      referenceName: 'ds_find_licenseplates',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_lots',
      name: 'ds_find_lots' + ' ('+ 'Returns' +')',
      referenceName: 'ds_find_lots',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorlotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorlotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorlotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_putaway_task_by_licenseplateId',
      name: 'ds_find_putaway_task_by_licenseplateId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_find_putaway_task_by_licenseplateId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_serialnumbers',
      name: 'ds_find_serialnumbers' + ' ('+ 'Returns' +')',
      referenceName: 'ds_find_serialnumbers',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'Returns' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'expectedTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'expectedTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'expectedTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_address_by_addressId',
      name: 'ds_get_address_by_addressId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_address_by_addressId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_annotation_tasks_by_orderId',
      name: 'ds_get_annotation_tasks_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_annotation_tasks_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_child_serial_numbers_by_serialNumberId',
      name: 'ds_get_child_serial_numbers_by_serialNumberId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_child_serial_numbers_by_serialNumberId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_load_tasks_by_licensePlateIds',
      name: 'ds_get_completed_load_tasks_by_licensePlateIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_completed_load_tasks_by_licensePlateIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_default_licenseplate_for_return_task',
      name: 'ds_get_default_licenseplate_for_return_task' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_default_licenseplate_for_return_task',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_license_plate_by_licensePlateCode',
      name: 'ds_get_license_plate_by_licensePlateCode' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_license_plate_by_licensePlateCode',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licensePlateCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_license_plate_by_licensePlateId',
      name: 'ds_get_license_plate_by_licensePlateId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_license_plate_by_licensePlateId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shipmentId_or_ids',
      name: 'ds_get_licenseplates_by_shipmentId_or_ids' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_licenseplates_by_shipmentId_or_ids',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_packagingId',
      name: 'ds_get_material_packaging_by_materialId_packagingId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_packagingId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_status_by_statusName',
      name: 'ds_get_material_status_by_statusName' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_material_status_by_statusName',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'statusName',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_statuses_by_statusIds',
      name: 'ds_get_material_statuses_by_statusIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_material_statuses_by_statusIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_by_projectId',
      name: 'ds_get_materials_by_projectId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_materials_by_projectId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_addresses_by_orderId',
      name: 'ds_get_order_addresses_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_addresses_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId',
      name: 'ds_get_order_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId_detailed',
      name: 'ds_get_order_by_orderId_detailed' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_by_orderId_detailed',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_details_by_shipmentId',
      name: 'ds_get_order_details_by_shipmentId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_details_by_shipmentId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_lines_by_orderId',
      name: 'ds_get_order_lines_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_lines_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_validation_results_by_orderId',
      name: 'ds_get_order_validation_results_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_order_validation_results_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_aggregated_expected',
      name: 'ds_get_orders_aggregated_expected' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_orders_aggregated_expected',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_by_orderIds',
      name: 'ds_get_orders_by_orderIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_orders_by_orderIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_outbound_shipment_licenseplatecontents',
      name: 'ds_get_outbound_shipment_licenseplatecontents' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_outbound_shipment_licenseplatecontents',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_receiving_tasks_by_orderId',
      name: 'ds_get_receiving_tasks_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_receiving_tasks_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_return_order_actuals',
      name: 'ds_get_return_order_actuals' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_return_order_actuals',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_return_order_expected',
      name: 'ds_get_return_order_expected' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_return_order_expected',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_return_orders_by_vendorReference',
      name: 'ds_get_return_orders_by_vendorReference' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_return_orders_by_vendorReference',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeOrderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeOrderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeOrderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_return_session_by_shippingContainerId',
      name: 'ds_get_return_session_by_shippingContainerId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_return_session_by_shippingContainerId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_return_tasks_by_targetLicensePlateId',
      name: 'ds_get_return_tasks_by_targetLicensePlateId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_return_tasks_by_targetLicensePlateId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'targetLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'targetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'targetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_scanned_material_details',
      name: 'ds_get_scanned_material_details' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_scanned_material_details',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lotNumber',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lotNumber',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lotNumber',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'serialNumberCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_serialNumberId',
      name: 'ds_get_serialnumber_by_serialNumberId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_serialnumber_by_serialNumberId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialNumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_licensePlateIds',
      name: 'ds_get_serialnumbers_by_licensePlateIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_serialnumbers_by_licensePlateIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_outboundShipmentId',
      name: 'ds_get_serialnumbers_by_outboundShipmentId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_serialnumbers_by_outboundShipmentId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_shipmentId',
      name: 'ds_get_shipmentLines_by_orderId_shipmentId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_shipmentId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_by_shipmentId',
      name: 'ds_get_shipment_by_shipmentId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_shipment_by_shipmentId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_orderId',
      name: 'ds_get_shipments_by_orderId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_shipments_by_orderId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_container_by_shippingContainerCode',
      name: 'ds_get_shipping_container_by_shippingContainerCode' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_shipping_container_by_shippingContainerCode',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipping_container_by_shippingContainerId',
      name: 'ds_get_shipping_container_by_shippingContainerId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_shipping_container_by_shippingContainerId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_task_by_taskId',
      name: 'ds_get_task_by_taskId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_task_by_taskId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_shippingContainerIds',
      name: 'ds_get_tasks_by_shippingContainerIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_tasks_by_shippingContainerIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_unused_licenseplates',
      name: 'ds_get_unused_licenseplates' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_unused_licenseplates',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_vendorlots_by_materialId',
      name: 'ds_get_vendorlots_by_materialId' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_vendorlots_by_materialId',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'Returns' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_license_plates_on_shipping_container_dd',
      name: 'ds_license_plates_on_shipping_container_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_license_plates_on_shipping_container_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_locations_dd',
      name: 'ds_locations_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_locations_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isLoose',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocation',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isReturnLocation',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isLoose',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocation',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isReturnLocation',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isLoose',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocation',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isReturnLocation',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_lot_statuses_dd',
      name: 'ds_lot_statuses_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_lot_statuses_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_lots_dd',
      name: 'ds_lots_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_lots_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_material_packagings_dd',
      name: 'ds_material_packagings_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_material_packagings_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_materials_by_project_dd',
      name: 'ds_materials_by_project_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_materials_by_project_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_classes_dd',
      name: 'ds_order_classes_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_order_classes_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_statuses_dd',
      name: 'ds_order_statuses_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_order_statuses_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_amount_widget',
      name: 'ds_orderline_total_amount_widget' + ' ('+ 'Returns' +')',
      referenceName: 'ds_orderline_total_amount_widget',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_gross_received_widget',
      name: 'ds_orderline_total_gross_received_widget' + ' ('+ 'Returns' +')',
      referenceName: 'ds_orderline_total_gross_received_widget',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_received_widget',
      name: 'ds_orderline_total_received_widget' + ' ('+ 'Returns' +')',
      referenceName: 'ds_orderline_total_received_widget',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_orders_eligible_for_return_grid',
      name: 'ds_outbound_orders_eligible_for_return_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_outbound_orders_eligible_for_return_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_owners_dd',
      name: 'ds_owners_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_owners_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_projects_dd',
      name: 'ds_projects_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_projects_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'gtin',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_asn_order_editor',
      name: 'ds_return_asn_order_editor' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_asn_order_editor',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_asn_order_licenseplates_grid',
      name: 'ds_return_asn_order_licenseplates_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_asn_order_licenseplates_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_asn_orderlines_grid',
      name: 'ds_return_asn_orderlines_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_asn_orderlines_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_license_plates_by_shippingcontainer_dd',
      name: 'ds_return_license_plates_by_shippingcontainer_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_license_plates_by_shippingcontainer_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_order_editor',
      name: 'ds_return_order_editor' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_order_editor',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_order_lines_grid',
      name: 'ds_return_order_lines_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_order_lines_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_orders_details_grid',
      name: 'ds_return_orders_details_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_orders_details_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_orders_grid',
      name: 'ds_return_orders_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_orders_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'blindShippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'blindShippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'blindShippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_orders_grid_properties',
      name: 'ds_return_orders_grid_properties' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_orders_grid_properties',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_orders_summary_report',
      name: 'ds_return_orders_summary_report' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_orders_summary_report',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'startDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'startDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'startDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_shipping_containers_grid',
      name: 'ds_return_shipping_containers_grid' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_shipping_containers_grid',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_return_shipping_containers_grid_testing',
      name: 'ds_return_shipping_containers_grid_testing' + ' ('+ 'Returns' +')',
      referenceName: 'ds_return_shipping_containers_grid_testing',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'isSourceShippingContainer',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_vendorlots_dd',
      name: 'ds_vendorlots_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_vendorlots_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouses_dd',
      name: 'ds_warehouses_dd' + ' ('+ 'Returns' +')',
      referenceName: 'ds_warehouses_dd',
      appReferenceName: 'Returns',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'auto_load_sales_order_flow',
      name: 'auto_load_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'auto_load_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'loadLocation',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_pick_sales_order_flow',
      name: 'auto_pick_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'auto_pick_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_manual_allocation_flow',
      name: 'cancel_manual_allocation_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'cancel_manual_allocation_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_order_line_flow',
      name: 'cancel_order_line_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'cancel_order_line_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'orderLineNumbers',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'reasonNotes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_pick_task_flow',
      name: 'cancel_pick_task_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'cancel_pick_task_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_sales_order_flow',
      name: 'cancel_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'cancel_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_sales_order_shipment_and_order_lines_flow',
      name: 'cancel_sales_order_shipment_and_order_lines_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'cancel_sales_order_shipment_and_order_lines_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'reasonCodeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_licenseplate_move_task_flow',
      name: 'complete_licenseplate_move_task_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'complete_licenseplate_move_task_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_manual_allocation_flow',
      name: 'complete_manual_allocation_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'complete_manual_allocation_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'currentPackagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'updatedPackagedAmount',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_sales_order_flow',
      name: 'complete_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'complete_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'copy_sales_order_flow',
      name: 'copy_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'copy_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'copyAddresses',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_manual_allocation_flow',
      name: 'create_manual_allocation_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_manual_allocation_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'licensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'sourceLocationId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_sales_order_flow',
      name: 'create_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderClassId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'waitStatus',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_sales_order_line_flow',
      name: 'create_sales_order_line_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_sales_order_line_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorlotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialnumberId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_sales_order_line_in_wait_status_flow',
      name: 'create_sales_order_line_in_wait_status_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_sales_order_line_in_wait_status_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'serialnumberId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_single_wave_flow',
      name: 'create_single_wave_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_single_wave_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'description',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'priorityId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'batchPickLocationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_wave_sales_order_flow',
      name: 'create_wave_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'create_wave_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_sales_order_flow',
      name: 'delete_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'delete_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_sales_order_line_flow',
      name: 'delete_sales_order_line_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'delete_sales_order_line_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lineNumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'excel_export_flow',
      name: 'excel_export_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'excel_export_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'ownerId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouseIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'statusIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'fromDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'toDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceTypeId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'materialIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'fullTextSearch',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'exportLimit',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'exportType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'dateType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'dateFormat',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'countries',
            type: 'string',
            isCollection: true
          },
          {
            required: false,
            id: 'waveId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'ship_to_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'format_order_lookupcode_flow',
      name: 'format_order_lookupcode_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'format_order_lookupcode_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additionalData',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'format_order_lookupcode_flow_multiple',
      name: 'format_order_lookupcode_flow_multiple' + ' ('+ 'SalesOrders' +')',
      referenceName: 'format_order_lookupcode_flow_multiple',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'context',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_order_signature_by_orderId_flow',
      name: 'get_order_signature_by_orderId_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'get_order_signature_by_orderId_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
    },
    {
      key: 'get_sales_order_line_availability_flow',
      name: 'get_sales_order_line_availability_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'get_sales_order_line_availability_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialnumberId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_sales_order_state',
      name: 'get_sales_order_state' + ' ('+ 'SalesOrders' +')',
      referenceName: 'get_sales_order_state',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'group_pick_tasks_by_license_plate_flow',
      name: 'group_pick_tasks_by_license_plate_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'group_pick_tasks_by_license_plate_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'task_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'is_manual_allocation_cancelable_flow',
      name: 'is_manual_allocation_cancelable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_manual_allocation_cancelable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_pick_task_cancelable_flow',
      name: 'is_pick_task_cancelable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_pick_task_cancelable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_cancelable_flow',
      name: 'is_sales_order_cancelable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_cancelable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_completable_flow',
      name: 'is_sales_order_completable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_completable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_deletable_flow',
      name: 'is_sales_order_deletable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_deletable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_line_deletable_flow',
      name: 'is_sales_order_line_deletable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_line_deletable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lineNumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_loadable_flow',
      name: 'is_sales_order_loadable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_loadable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_pickable_flow',
      name: 'is_sales_order_pickable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_pickable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_processable_flow',
      name: 'is_sales_order_processable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_processable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_revertable_flow',
      name: 'is_sales_order_revertable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_revertable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_sales_order_soft_allocatable_flow',
      name: 'is_sales_order_soft_allocatable_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'is_sales_order_soft_allocatable_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'pick_full_license_plate',
      name: 'pick_full_license_plate' + ' ('+ 'SalesOrders' +')',
      referenceName: 'pick_full_license_plate',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'pick_on_existing_license_plate',
      name: 'pick_on_existing_license_plate' + ' ('+ 'SalesOrders' +')',
      referenceName: 'pick_on_existing_license_plate',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagedPackId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'sourceLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumberIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'pick_on_new_license_plate',
      name: 'pick_on_new_license_plate' + ' ('+ 'SalesOrders' +')',
      referenceName: 'pick_on_new_license_plate',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packagedPackId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'sourceLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLicensePlateLookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumberIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'process_sales_order_flow',
      name: 'process_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'process_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'processing_strategy_workflow_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'break_child_serials',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_single_wave_flow',
      name: 'process_single_wave_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'process_single_wave_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'waveId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'createManualAllocationTasks',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_wave_sales_order_flow',
      name: 'process_wave_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'process_wave_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'is_create_manual_allocation_tasks',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'reallocate_pick_task_flow',
      name: 'reallocate_pick_task_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'reallocate_pick_task_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'currentPackagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'updatedPackagedAmount',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'release_single_wave_flow',
      name: 'release_single_wave_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'release_single_wave_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'waveId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'release_wave_sales_order_flow',
      name: 'release_wave_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'release_wave_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'revert_sales_order_flow',
      name: 'revert_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'revert_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'substitution_sales_order_flow',
      name: 'substitution_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'substitution_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'transfer_auto_pick_sales_order_flow',
      name: 'transfer_auto_pick_sales_order_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'transfer_auto_pick_sales_order_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'transfer_licenseplates_to_new_salesorder_flow',
      name: 'transfer_licenseplates_to_new_salesorder_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'transfer_licenseplates_to_new_salesorder_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'unload_shipping_licenseplate_flow',
      name: 'unload_shipping_licenseplate_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'unload_shipping_licenseplate_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'unpick_inventory_flow',
      name: 'unpick_inventory_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'unpick_inventory_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'unpickPackagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'targetLicensePlate',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumberIds',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'shipmentLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'unpick_licenseplate_flow',
      name: 'unpick_licenseplate_flow' + ' ('+ 'SalesOrders' +')',
      referenceName: 'unpick_licenseplate_flow',
      appReferenceName: 'SalesOrders',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_container_sizes_dd',
      name: 'ds_container_sizes_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_container_sizes_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_countries_dd',
      name: 'ds_countries_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_countries_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_freight_terms_dd',
      name: 'ds_freight_terms_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_freight_terms_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_licenseplateId_lotId',
      name: 'ds_get_available_inventory_by_licenseplateId_lotId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_licenseplateId_lotId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_locationId',
      name: 'ds_get_available_inventory_by_lotId_locationId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_locationId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_packagedId_locationId',
      name: 'ds_get_available_inventory_by_lotId_packagedId_locationId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_packagedId_locationId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_warehouseId',
      name: 'ds_get_available_inventory_by_lotId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'eligibleForAllocationCheck',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_materialId_warehouseId',
      name: 'ds_get_available_inventory_by_materialId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_available_inventory_by_materialId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_picking_tasks_by_orderId_shipmentId_top1',
      name: 'ds_get_completed_picking_tasks_by_orderId_shipmentId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_completed_picking_tasks_by_orderId_shipmentId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_tasks_by_orderId_top1',
      name: 'ds_get_completed_tasks_by_orderId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_completed_tasks_by_orderId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_containercodes_by_shippingContainerId',
      name: 'ds_get_containercodes_by_shippingContainerId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_containercodes_by_shippingContainerId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shippingContainerId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_full_licenseplate_pick_task_child_tasks',
      name: 'ds_get_full_licenseplate_pick_task_child_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_full_licenseplate_pick_task_child_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_full_licenseplate_picking_tasks_by_shipmentId',
      name: 'ds_get_full_licenseplate_picking_tasks_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_full_licenseplate_picking_tasks_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_hardallocationdetails_by_hardallocationId',
      name: 'ds_get_hardallocationdetails_by_hardallocationId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_hardallocationdetails_by_hardallocationId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_hardallocations_by_taskId',
      name: 'ds_get_hardallocations_by_taskId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_hardallocations_by_taskId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_licenseplateId',
      name: 'ds_get_licenseplate_by_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplate_by_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId',
      name: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_loose_locationId',
      name: 'ds_get_licenseplate_by_loose_locationId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplate_by_loose_locationId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_licenseplateId',
      name: 'ds_get_licenseplatecontents_by_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplatecontents_by_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId',
      name: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId',
      name: 'ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_locationId_lotId_packagingId',
      name: 'ds_get_licenseplates_by_locationId_lotId_packagingId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplates_by_locationId_lotId_packagingId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_lookupcode_and_warehouseId',
      name: 'ds_get_licenseplates_by_lookupcode_and_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplates_by_lookupcode_and_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shipmentId',
      name: 'ds_get_licenseplates_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_licenseplates_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'filter_out_children',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'filter_out_children',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'filter_out_children',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_loading_tasks_by_licenseplateId_shipmentId',
      name: 'ds_get_loading_tasks_by_licenseplateId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_loading_tasks_by_licenseplateId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_location_by_locationId',
      name: 'ds_get_location_by_locationId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_location_by_locationId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_location_by_lotId_top1',
      name: 'ds_get_location_by_lotId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_location_by_lotId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_lot_by_lotId',
      name: 'ds_get_lot_by_lotId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_lot_by_lotId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_lot_serial_weight_avg',
      name: 'ds_get_lot_serial_weight_avg' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_lot_serial_weight_avg',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_by_materialId',
      name: 'ds_get_material_by_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_by_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_kit_order_line_by_order_id_top1',
      name: 'ds_get_material_kit_order_line_by_order_id_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_kit_order_line_by_order_id_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_kit_order_lines_by_order_id',
      name: 'ds_get_material_kit_order_lines_by_order_id' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_kit_order_lines_by_order_id',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId',
      name: 'ds_get_material_packaging_by_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_packaging_by_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_and_packagingId',
      name: 'ds_get_material_packaging_by_materialId_and_packagingId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_and_packagingId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_and_packagingId_top1',
      name: 'ds_get_material_packaging_by_materialId_and_packagingId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_and_packagingId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_licenseplateId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_locationId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_licenseplateId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_locationId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_packagingId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_lotId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId',
      name: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_fixed_weight_details_by_shipmentId',
      name: 'ds_get_non_fixed_weight_details_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_fixed_weight_details_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_non_fixed_weight_details_by_shipmentId_completed',
      name: 'ds_get_non_fixed_weight_details_by_shipmentId_completed' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_non_fixed_weight_details_by_shipmentId_completed',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_account_address_by_orderId',
      name: 'ds_get_order_account_address_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_account_address_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_address_by_orderId_top1',
      name: 'ds_get_order_address_by_orderId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_address_by_orderId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_aggregated_tasks',
      name: 'ds_get_order_aggregated_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_aggregated_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_licenseplate',
      name: 'ds_get_order_by_licenseplate' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_by_licenseplate',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_lookupcode',
      name: 'ds_get_order_by_lookupcode' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_by_lookupcode',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId',
      name: 'ds_get_order_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId_top1',
      name: 'ds_get_order_by_orderId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_by_orderId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_lines_with_serial_numbers',
      name: 'ds_get_order_lines_with_serial_numbers' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_lines_with_serial_numbers',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_owner_contact_top1',
      name: 'ds_get_order_owner_contact_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_owner_contact_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_ship_to_account_vs_order_address',
      name: 'ds_get_order_ship_to_account_vs_order_address' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_ship_to_account_vs_order_address',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_signature_attachment',
      name: 'ds_get_order_signature_attachment' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_signature_attachment',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_picked_units_by_orderId',
      name: 'ds_get_order_total_picked_units_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_picked_units_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_picked_units_by_orderIds',
      name: 'ds_get_order_total_picked_units_by_orderIds' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_picked_units_by_orderIds',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_picked_units_by_various',
      name: 'ds_get_order_total_picked_units_by_various' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_picked_units_by_various',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_picked_vs_expected_units_by_orderId',
      name: 'ds_get_order_total_picked_vs_expected_units_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_picked_vs_expected_units_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_units_by_orderId',
      name: 'ds_get_order_total_units_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_units_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_total_units_by_orderIds',
      name: 'ds_get_order_total_units_by_orderIds' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_total_units_by_orderIds',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_warehouse_contact_top1',
      name: 'ds_get_order_warehouse_contact_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_order_warehouse_contact_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderaddresses_by_orderId',
      name: 'ds_get_orderaddresses_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderaddresses_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderline_by_order_id_line_number',
      name: 'ds_get_orderline_by_order_id_line_number' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderline_by_order_id_line_number',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'line_number',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderline_total_picked_units_by_orderId_linenumber',
      name: 'ds_get_orderline_total_picked_units_by_orderId_linenumber' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderline_total_picked_units_by_orderId_linenumber',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lineNumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId',
      name: 'ds_get_orderlines_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_bottom1',
      name: 'ds_get_orderlines_by_orderId_bottom1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId_bottom1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_without_children',
      name: 'ds_get_orderlines_by_orderId_without_children' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderlines_by_orderId_without_children',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_missing_shipment_by_orderId',
      name: 'ds_get_orderlines_missing_shipment_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orderlines_missing_shipment_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_aggregated_tasks',
      name: 'ds_get_orders_aggregated_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orders_aggregated_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_by_shipmentId',
      name: 'ds_get_orders_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_orders_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_outbound_processing_strategies_by_projectId',
      name: 'ds_get_outbound_processing_strategies_by_projectId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_outbound_processing_strategies_by_projectId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_picking_tasks_by_orderId_shipmentId',
      name: 'ds_get_picking_tasks_by_orderId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_picking_tasks_by_orderId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pickslips_by_waveId',
      name: 'ds_get_pickslips_by_waveId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_pickslips_by_waveId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_released_manual_allocations_by_orderId_shipmentId_top1',
      name: 'ds_get_released_manual_allocations_by_orderId_shipmentId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_released_manual_allocations_by_orderId_shipmentId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_released_pick_tasks_by_chainhead',
      name: 'ds_get_released_pick_tasks_by_chainhead' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_released_pick_tasks_by_chainhead',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainhead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_released_picking_tasks_by_orderId_shipmentId_top1',
      name: 'ds_get_released_picking_tasks_by_orderId_shipmentId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_released_picking_tasks_by_orderId_shipmentId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_serialnumberId',
      name: 'ds_get_serialnumber_by_serialnumberId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumber_by_serialnumberId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialnumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_lotId_packagedId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_lotId_packagedId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_shipmentId',
      name: 'ds_get_shipmentLines_by_orderId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_top1',
      name: 'ds_get_shipmentLines_by_orderId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_shipmentId',
      name: 'ds_get_shipmentLines_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_shipmentId_top1',
      name: 'ds_get_shipmentLines_by_shipmentId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentLines_by_shipmentId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_aggregated_licenseplates',
      name: 'ds_get_shipment_aggregated_licenseplates' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipment_aggregated_licenseplates',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_aggregated_tasks',
      name: 'ds_get_shipment_aggregated_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipment_aggregated_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_by_orderId_top1',
      name: 'ds_get_shipment_by_orderId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipment_by_orderId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentline_by_shipmentlineId',
      name: 'ds_get_shipmentline_by_shipmentlineId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentline_by_shipmentlineId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentlineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentline_by_shipmentlineId_detailed',
      name: 'ds_get_shipmentline_by_shipmentlineId_detailed' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentline_by_shipmentlineId_detailed',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentlineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentlines_by_orderId_bottom1',
      name: 'ds_get_shipmentlines_by_orderId_bottom1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentlines_by_orderId_bottom1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_aggregated_licenseplates',
      name: 'ds_get_shipments_aggregated_licenseplates' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipments_aggregated_licenseplates',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_aggregated_tasks',
      name: 'ds_get_shipments_aggregated_tasks' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipments_aggregated_tasks',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_orderId',
      name: 'ds_get_shipments_by_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipments_by_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_shipmentId',
      name: 'ds_get_shipments_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipments_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_from_orders',
      name: 'ds_get_shipments_from_orders' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipments_from_orders',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentscontents_by_orderId_shipmentId_top1',
      name: 'ds_get_shipmentscontents_by_orderId_shipmentId_top1' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shipmentscontents_by_orderId_shipmentId_top1',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontainers_by_shipmentId',
      name: 'ds_get_shippingcontainers_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontainers_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_by_licenseplateId',
      name: 'ds_get_shippingcontents_by_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_by_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_by_shipmentId_orderId',
      name: 'ds_get_shippingcontents_by_shipmentId_orderId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_by_shipmentId_orderId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId',
      name: 'ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_by_shipmentlineId_lotId_packagedId_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentlineId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId',
      name: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId',
      name: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_lotId_packagedId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId',
      name: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_task_by_taskId',
      name: 'ds_get_task_by_taskId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_task_by_taskId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_licenseplateId',
      name: 'ds_get_tasks_by_licenseplateId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_tasks_by_licenseplateId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_orderId_orderLineNumbers',
      name: 'ds_get_tasks_by_orderId_orderLineNumbers' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_tasks_by_orderId_orderLineNumbers',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumbers',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumbers',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumbers',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_orderId_shipmentId',
      name: 'ds_get_tasks_by_orderId_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_tasks_by_orderId_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_shipmentId',
      name: 'ds_get_tasks_by_shipmentId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_tasks_by_shipmentId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_by_warehouseId',
      name: 'ds_get_warehouse_by_warehouseId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_warehouse_by_warehouseId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wave_by_waveId',
      name: 'ds_get_wave_by_waveId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_get_wave_by_waveId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_licenseplates_by_locationId_lotId_packagingId_dd',
      name: 'ds_licenseplates_by_locationId_lotId_packagingId_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_licenseplates_by_locationId_lotId_packagingId_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_load_slip_order_lines_report',
      name: 'ds_load_slip_order_lines_report' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_load_slip_order_lines_report',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_locations_eligible_for_allocation_dd',
      name: 'ds_locations_eligible_for_allocation_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_locations_eligible_for_allocation_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_manual_allocations_grid',
      name: 'ds_manual_allocations_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_manual_allocations_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_loaded_percentage_widget',
      name: 'ds_order_loaded_percentage_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_loaded_percentage_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_picked_percentage_widget',
      name: 'ds_order_picked_percentage_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_picked_percentage_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_completed_order_count_by_date',
      name: 'ds_order_total_completed_order_count_by_date' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_completed_order_count_by_date',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_completed_order_count_by_date_widget',
      name: 'ds_order_total_completed_order_count_by_date_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_completed_order_count_by_date_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_pallet_count_widget',
      name: 'ds_order_total_pallet_count_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_pallet_count_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_gross_by_date',
      name: 'ds_order_total_picked_gross_by_date' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_gross_by_date',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_gross_by_date_widget',
      name: 'ds_order_total_picked_gross_by_date_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_gross_by_date_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_gross_widget',
      name: 'ds_order_total_picked_gross_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_gross_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_order_count_by_date',
      name: 'ds_order_total_picked_order_count_by_date' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_order_count_by_date',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_order_count_by_date_widget',
      name: 'ds_order_total_picked_order_count_by_date_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_order_count_by_date_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_units',
      name: 'ds_order_total_picked_units' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_units',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_units_by_date',
      name: 'ds_order_total_picked_units_by_date' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_units_by_date',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_units_by_date_widget',
      name: 'ds_order_total_picked_units_by_date_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_units_by_date_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_units_widget',
      name: 'ds_order_total_picked_units_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_units_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_weight_by_orderId_lotId_packagedId',
      name: 'ds_order_total_picked_weight_by_orderId_lotId_packagedId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_weight_by_orderId_lotId_packagedId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_picked_weight_by_orderId_materialId',
      name: 'ds_order_total_picked_weight_by_orderId_materialId' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_picked_weight_by_orderId_materialId',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_order_total_units_widget',
      name: 'ds_order_total_units_widget' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_order_total_units_widget',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderclasses_dd',
      name: 'ds_orderclasses_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_orderclasses_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderstatuses_dd',
      name: 'ds_orderstatuses_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_orderstatuses_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_details_bill_of_lading_report',
      name: 'ds_outbound_details_bill_of_lading_report' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_details_bill_of_lading_report',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_details_bill_of_lading_report_advanced',
      name: 'ds_outbound_details_bill_of_lading_report_advanced' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_details_bill_of_lading_report_advanced',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_header_bill_of_lading_report',
      name: 'ds_outbound_header_bill_of_lading_report' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_header_bill_of_lading_report',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_orders_details_grid',
      name: 'ds_outbound_orders_details_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_orders_details_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_orders_grid',
      name: 'ds_outbound_orders_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_orders_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fromDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'toDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'countries',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'carrierId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'carrierServiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'skip',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'ship_to_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_pick_slip_by_orderId_report',
      name: 'ds_outbound_pick_slip_by_orderId_report' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_outbound_pick_slip_by_orderId_report',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_sales_order_editor',
      name: 'ds_sales_order_editor' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_sales_order_editor',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_sales_order_lines_grid',
      name: 'ds_sales_order_lines_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_sales_order_lines_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'has_children',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'has_children',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'has_children',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_sales_order_picking_grid',
      name: 'ds_sales_order_picking_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_sales_order_picking_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_sales_orders_children_tasks_grid',
      name: 'ds_sales_orders_children_tasks_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_sales_orders_children_tasks_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_sales_orders_tasks_grid',
      name: 'ds_sales_orders_tasks_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_sales_orders_tasks_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipment_archived_contents',
      name: 'ds_shipment_archived_contents' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shipment_archived_contents',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipment_contents',
      name: 'ds_shipment_contents' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shipment_contents',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'loadTasks',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'loadTasks',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'loadTasks',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipment_expected',
      name: 'ds_shipment_expected' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shipment_expected',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'available',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'available',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'available',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipment_priorities_dd',
      name: 'ds_shipment_priorities_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shipment_priorities_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shipment_processed',
      name: 'ds_shipment_processed' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shipment_processed',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_shippingcontents_grid',
      name: 'ds_shippingcontents_grid' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_shippingcontents_grid',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_wave_priorities_dd',
      name: 'ds_wave_priorities_dd' + ' ('+ 'SalesOrders' +')',
      referenceName: 'ds_wave_priorities_dd',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'testing_flow_based_data_source_for_grids',
      name: 'testing_flow_based_data_source_for_grids' + ' ('+ 'SalesOrders' +')',
      referenceName: 'testing_flow_based_data_source_for_grids',
      appReferenceName: 'SalesOrders',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'check_footprint_version',
      name: 'check_footprint_version' + ' ('+ 'Utilities' +')',
      referenceName: 'check_footprint_version',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'minimumVersion',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations',
      name: 'configurations' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_delete',
      name: 'configurations_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_distinct',
      name: 'configurations_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_get',
      name: 'configurations_get' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_get_one',
      name: 'configurations_get_one' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_get_one',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_purge',
      name: 'configurations_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_update',
      name: 'configurations_update' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_write',
      name: 'configurations_write' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_dates_between_timezones_flow',
      name: 'convert_dates_between_timezones_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_dates_between_timezones_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseIds',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'convertDates',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'isFromUtcToWarehouse',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_distance_flow',
      name: 'convert_distance_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_distance_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputDistance',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_volume_flow',
      name: 'convert_volume_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_volume_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputVolume',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_weight_flow',
      name: 'convert_weight_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_weight_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputWeight',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_batch_request_flow',
      name: 'crud_batch_request_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_batch_request_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entities',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'withTransaction',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_create_flow',
      name: 'crud_create_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_create_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_dual_pk_flow',
      name: 'crud_delete_dual_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_dual_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_dual_pk_flow_string',
      name: 'crud_delete_dual_pk_flow_string' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_dual_pk_flow_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_flow',
      name: 'crud_delete_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_quad_pk_flow',
      name: 'crud_delete_quad_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_quad_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_triple_pk_flow',
      name: 'crud_delete_triple_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_triple_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_dual_pk_flow',
      name: 'crud_update_dual_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_dual_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_dual_pk_flow_string',
      name: 'crud_update_dual_pk_flow_string' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_dual_pk_flow_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_flow',
      name: 'crud_update_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_quad_pk_flow',
      name: 'crud_update_quad_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_quad_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_triple_pk_flow',
      name: 'crud_update_triple_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_triple_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'date_get_from_footprint',
      name: 'date_get_from_footprint' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_from_footprint',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'date_get_utc_flow',
      name: 'date_get_utc_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_utc_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouseDate',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'date_get_warehouse_flow',
      name: 'date_get_warehouse_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_warehouse_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'utcDate',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'flatten_object_property',
      name: 'flatten_object_property' + ' ('+ 'Utilities' +')',
      referenceName: 'flatten_object_property',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'flattenArray',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'childName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'flattenValueName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'returnReversed',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'format_username_flow',
      name: 'format_username_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'format_username_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inUserName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_username_flow',
      name: 'get_username_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'get_username_flow',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'grammar_format_string_array_flow',
      name: 'grammar_format_string_array_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'grammar_format_string_array_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'values',
            type: 'string',
            isCollection: true
          },
          {
            required: false,
            id: 'delimiter',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'finalValueDelimiter',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_applications',
      name: 'list_applications' + ' ('+ 'Utilities' +')',
      referenceName: 'list_applications',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_processes',
      name: 'list_processes' + ' ('+ 'Utilities' +')',
      referenceName: 'list_processes',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'process_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_statuses',
      name: 'list_statuses' + ' ('+ 'Utilities' +')',
      referenceName: 'list_statuses',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_transaction_types',
      name: 'list_transaction_types' + ' ('+ 'Utilities' +')',
      referenceName: 'list_transaction_types',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs',
      name: 'logs' + ' ('+ 'Utilities' +')',
      referenceName: 'logs',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_clean',
      name: 'logs_clean' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_clean',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_delete',
      name: 'logs_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_distinct',
      name: 'logs_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_get',
      name: 'logs_get' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_purge',
      name: 'logs_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_update',
      name: 'logs_update' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_write',
      name: 'logs_write' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'math_round_flow',
      name: 'math_round_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'math_round_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputNumber',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'position',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages',
      name: 'messages' + ' ('+ 'Utilities' +')',
      referenceName: 'messages',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_clean',
      name: 'messages_clean' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_clean',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_delete',
      name: 'messages_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_distinct',
      name: 'messages_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_get',
      name: 'messages_get' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_next',
      name: 'messages_next' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_next',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_purge',
      name: 'messages_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_purge_string',
      name: 'messages_purge_string' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_purge_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_update',
      name: 'messages_update' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_write',
      name: 'messages_write' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'odata_encode_special_characters_for_url',
      name: 'odata_encode_special_characters_for_url' + ' ('+ 'Utilities' +')',
      referenceName: 'odata_encode_special_characters_for_url',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'stringToEncode',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'reserve_batchId_flow',
      name: 'reserve_batchId_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'reserve_batchId_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'batchSize',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'reserve_nextId_flow',
      name: 'reserve_nextId_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'reserve_nextId_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_populate_date_numer',
      name: 'storage_populate_date_numer' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_populate_date_numer',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'storage_purge',
      name: 'storage_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_purge',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'storage_test',
      name: 'storage_test' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_test',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'messages',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'string_encrypt',
      name: 'string_encrypt' + ' ('+ 'Utilities' +')',
      referenceName: 'string_encrypt',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypted',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'upload_file_to_blob_storage_flow',
      name: 'upload_file_to_blob_storage_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'upload_file_to_blob_storage_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'storageAccount',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'signature',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'container',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'fileContent',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'fileName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_component_required_fields',
      name: 'validate_component_required_fields' + ' ('+ 'Utilities' +')',
      referenceName: 'validate_component_required_fields',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'componentType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'component',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_applications_dd',
      name: 'ds_applications_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_applications_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_configurations_grid',
      name: 'ds_configurations_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_configurations_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse',
      name: 'ds_get_first_warehouse' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_get_first_warehouse',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_logs_grid',
      name: 'ds_logs_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_logs_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_messages_grid',
      name: 'ds_messages_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_messages_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_processes_dd',
      name: 'ds_processes_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_processes_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_statuses_dd',
      name: 'ds_statuses_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_statuses_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_transaction_types_dd',
      name: 'ds_transaction_types_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_transaction_types_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'add_licenseplates_from_outbound_to_inbound_order_flow',
      name: 'add_licenseplates_from_outbound_to_inbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'add_licenseplates_from_outbound_to_inbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouse_transfer_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'adjust_target_inbound_order_orderline_and_shipmentline_flow',
      name: 'adjust_target_inbound_order_orderline_and_shipmentline_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'adjust_target_inbound_order_orderline_and_shipmentline_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'adjust_packaged_amount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'shipment_line_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_line_number',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_fulfill_warehouse_transfer_flow',
      name: 'auto_fulfill_warehouse_transfer_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'auto_fulfill_warehouse_transfer_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouse_transfer_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_load_source_outbound_order_flow',
      name: 'auto_load_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'auto_load_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'load_location_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_pick_source_outbound_order_flow',
      name: 'auto_pick_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'auto_pick_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_receive_target_inbound_order_flow',
      name: 'auto_receive_target_inbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'auto_receive_target_inbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'auto_receive_target_inbound_task_flow',
      name: 'auto_receive_target_inbound_task_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'auto_receive_target_inbound_task_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_manual_allocation_flow',
      name: 'cancel_manual_allocation_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'cancel_manual_allocation_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_pick_task_flow',
      name: 'cancel_pick_task_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'cancel_pick_task_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_warehouse_transfer_flow',
      name: 'cancel_warehouse_transfer_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'cancel_warehouse_transfer_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_source_outbound_order_flow',
      name: 'complete_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'complete_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'complete_target_inbound_order_flow',
      name: 'complete_target_inbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'complete_target_inbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_or_update_warehouse_transfer_line_flow',
      name: 'create_or_update_warehouse_transfer_line_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'create_or_update_warehouse_transfer_line_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'line_number',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'warehouse_transfer_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'vendor_lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaging_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaged_amount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'license_plate_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serial_number_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_warehouse_transfer_flow',
      name: 'create_warehouse_transfer_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'create_warehouse_transfer_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'project_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'source_warehouse_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'target_warehouse_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'lookup',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'outbound_order_lookup',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'inbound_order_lookup',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_warehouse_transfer_from_completed_inbound_order_flow',
      name: 'create_warehouse_transfer_from_completed_inbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'create_warehouse_transfer_from_completed_inbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'target_warehouse_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_wave_source_outbound_order_flow',
      name: 'create_wave_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'create_wave_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_warehouse_transfer_flow',
      name: 'delete_warehouse_transfer_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'delete_warehouse_transfer_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseTransferId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_warehouse_transfer_line_flow',
      name: 'delete_warehouse_transfer_line_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'delete_warehouse_transfer_line_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseTransferId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lineNumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_outbound_order_line_availability_flow',
      name: 'get_outbound_order_line_availability_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'get_outbound_order_line_availability_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialnumberId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_warehouse_transfer_status_flow',
      name: 'get_warehouse_transfer_status_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'get_warehouse_transfer_status_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouse_transfer_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_manual_allocation_cancelable_flow',
      name: 'is_manual_allocation_cancelable_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'is_manual_allocation_cancelable_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_outbound_order_line_deletable_flow',
      name: 'is_outbound_order_line_deletable_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'is_outbound_order_line_deletable_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lineNumber',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_pick_task_cancelable_flow',
      name: 'is_pick_task_cancelable_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'is_pick_task_cancelable_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_warehouse_transfer_deletable',
      name: 'is_warehouse_transfer_deletable' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'is_warehouse_transfer_deletable',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseTransferId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'optimalInputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'pick_on_licenseplate_flow',
      name: 'pick_on_licenseplate_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'pick_on_licenseplate_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packagingId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'sourceLicensePlateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLicensePlateLookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'targetLocationId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serialNumberIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'targetLicensePlateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_source_outbound_order_flow',
      name: 'process_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'process_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_target_inbound_order_flow',
      name: 'process_target_inbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'process_target_inbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'process_wave_source_outbound_order_flow',
      name: 'process_wave_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'process_wave_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'reallocate_pick_task_flow',
      name: 'reallocate_pick_task_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'reallocate_pick_task_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'taskId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'locationId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'currentPackagedAmount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'updatedPackagedAmount',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'release_wave_source_outbound_order_flow',
      name: 'release_wave_source_outbound_order_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'release_wave_source_outbound_order_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'set_target_inbound_receiving_task_flow',
      name: 'set_target_inbound_receiving_task_flow' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'set_target_inbound_receiving_task_flow',
      appReferenceName: 'WarehouseTransfers',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'license_plate_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaging_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'vendor_lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaged_amount',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'existing_task_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'serial_numbers_to_create',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'serial_number_ids_to_delete',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'ds_find_putaway_task_by_licenseplateId',
      name: 'ds_find_putaway_task_by_licenseplateId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_find_putaway_task_by_licenseplateId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_packagedId_locationId',
      name: 'ds_get_available_inventory_by_lotId_packagedId_locationId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_packagedId_locationId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_available_inventory_by_lotId_packagedId_warehouseId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId',
      name: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_available_inventory_by_materialId_packagedId_warehouseId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_hardallocationdetails_by_hardallocationId',
      name: 'ds_get_hardallocationdetails_by_hardallocationId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_hardallocationdetails_by_hardallocationId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'hardallocationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_hardallocations_by_taskId',
      name: 'ds_get_hardallocations_by_taskId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_hardallocations_by_taskId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inbound_order_receiving_task',
      name: 'ds_get_inbound_order_receiving_task' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_inbound_order_receiving_task',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'taskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_licenseplateId',
      name: 'ds_get_licenseplate_by_licenseplateId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplate_by_licenseplateId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId',
      name: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_loose_locationId',
      name: 'ds_get_licenseplate_by_loose_locationId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplate_by_loose_locationId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId',
      name: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_locationId_lotId_packagingId',
      name: 'ds_get_licenseplates_by_locationId_lotId_packagingId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplates_by_locationId_lotId_packagingId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagedId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shipmentId',
      name: 'ds_get_licenseplates_by_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplates_by_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplates_by_shipmentId_or_ids',
      name: 'ds_get_licenseplates_by_shipmentId_or_ids' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_licenseplates_by_shipmentId_or_ids',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_loading_tasks_by_licenseplateId_shipmentId',
      name: 'ds_get_loading_tasks_by_licenseplateId_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_loading_tasks_by_licenseplateId_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_location_by_locationId',
      name: 'ds_get_location_by_locationId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_location_by_locationId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'locationId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_lot_by_lotId',
      name: 'ds_get_lot_by_lotId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_lot_by_lotId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_by_materialId',
      name: 'ds_get_material_by_materialId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_material_by_materialId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorlotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packaging_by_materialId_and_packagingId',
      name: 'ds_get_material_packaging_by_materialId_and_packagingId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_material_packaging_by_materialId_and_packagingId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_account_address_by_orderId',
      name: 'ds_get_order_account_address_by_orderId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_account_address_by_orderId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_address_by_orderId_top1',
      name: 'ds_get_order_address_by_orderId_top1' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_address_by_orderId_top1',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId',
      name: 'ds_get_order_by_orderId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_by_orderId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_classes_by_orderClassIds',
      name: 'ds_get_order_classes_by_orderClassIds' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_classes_by_orderClassIds',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderClassIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_owner_contact_top1',
      name: 'ds_get_order_owner_contact_top1' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_owner_contact_top1',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_ship_to_account_vs_order_address',
      name: 'ds_get_order_ship_to_account_vs_order_address' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_order_ship_to_account_vs_order_address',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderline_total_picked_units_by_orderId_linenumber',
      name: 'ds_get_orderline_total_picked_units_by_orderId_linenumber' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_orderline_total_picked_units_by_orderId_linenumber',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lineNumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_content',
      name: 'ds_get_orderlines_by_content' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_orderlines_by_content',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'vendorLotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId',
      name: 'ds_get_orderlines_by_orderId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_orderlines_by_orderId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_bottom1',
      name: 'ds_get_orderlines_by_orderId_bottom1' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_orderlines_by_orderId_bottom1',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId_linenumber',
      name: 'ds_get_orderlines_by_orderId_linenumber' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_orderlines_by_orderId_linenumber',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'linenumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_outbound_shipment_licenseplatecontents',
      name: 'ds_get_outbound_shipment_licenseplatecontents' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_outbound_shipment_licenseplatecontents',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_picking_tasks_by_orderId_shipmentId',
      name: 'ds_get_picking_tasks_by_orderId_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_picking_tasks_by_orderId_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_project_by_projectId',
      name: 'ds_get_project_by_projectId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_project_by_projectId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_putaway_task_by_licenseplateId',
      name: 'ds_get_putaway_task_by_licenseplateId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_putaway_task_by_licenseplateId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumber_by_serialnumberId',
      name: 'ds_get_serialnumber_by_serialnumberId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_serialnumber_by_serialnumberId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialnumberId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      name: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_serialnumbers_by_licenseplateId_lotId_packagingId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_by_outboundShipmentId',
      name: 'ds_get_serialnumbers_by_outboundShipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_serialnumbers_by_outboundShipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId',
      name: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_serialnumbers_non_fixed_weight_by_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_by_shipmentId',
      name: 'ds_get_shipment_by_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_shipment_by_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentline_by_shipmentlineId',
      name: 'ds_get_shipmentline_by_shipmentlineId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_shipmentline_by_shipmentlineId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentlines_by_orderId_bottom1',
      name: 'ds_get_shipmentlines_by_orderId_bottom1' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_shipmentlines_by_orderId_bottom1',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentlines_by_orderline',
      name: 'ds_get_shipmentlines_by_orderline' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_shipmentlines_by_orderline',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderLineNumber',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId',
      name: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_target_inbound_order_receiving_tasks',
      name: 'ds_get_target_inbound_order_receiving_tasks' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_target_inbound_order_receiving_tasks',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_chainHead',
      name: 'ds_get_tasks_by_chainHead' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_tasks_by_chainHead',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_orderId_shipmentId',
      name: 'ds_get_tasks_by_orderId_shipmentId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_tasks_by_orderId_shipmentId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_serialnumberIds',
      name: 'ds_get_tasks_by_serialnumberIds' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_tasks_by_serialnumberIds',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'serialnumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_transfer_by_lookupcode',
      name: 'ds_get_warehouse_transfer_by_lookupcode' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_warehouse_transfer_by_lookupcode',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_transfer_by_warehouseTransferId',
      name: 'ds_get_warehouse_transfer_by_warehouseTransferId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_warehouse_transfer_by_warehouseTransferId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId',
      name: 'ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseTransferId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'lineNumber',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wave_by_waveId',
      name: 'ds_get_wave_by_waveId' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_get_wave_by_waveId',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_amount_widget',
      name: 'ds_orderline_total_amount_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_orderline_total_amount_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_gross_received_widget',
      name: 'ds_orderline_total_gross_received_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_orderline_total_gross_received_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_orderline_total_received_widget',
      name: 'ds_orderline_total_received_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_orderline_total_received_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_detail_pick_slip_report',
      name: 'ds_outbound_detail_pick_slip_report' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_detail_pick_slip_report',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_details_bill_of_lading_report',
      name: 'ds_outbound_details_bill_of_lading_report' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_details_bill_of_lading_report',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_header_bill_of_lading_report',
      name: 'ds_outbound_header_bill_of_lading_report' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_header_bill_of_lading_report',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_header_pick_slip_report',
      name: 'ds_outbound_header_pick_slip_report' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_header_pick_slip_report',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_order_total_picked_gross_widget',
      name: 'ds_outbound_order_total_picked_gross_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_order_total_picked_gross_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_order_total_picked_units_widget',
      name: 'ds_outbound_order_total_picked_units_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_order_total_picked_units_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_outbound_order_total_units_widget',
      name: 'ds_outbound_order_total_units_widget' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_outbound_order_total_units_widget',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_editor',
      name: 'ds_warehouse_transfer_editor' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_editor',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseTransferId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_inbound_order_editor',
      name: 'ds_warehouse_transfer_inbound_order_editor' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_inbound_order_editor',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_inbound_order_licenseplates_grid',
      name: 'ds_warehouse_transfer_inbound_order_licenseplates_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_inbound_order_licenseplates_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid',
      name: 'ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_inbound_order_receiving_tasks_grid',
      name: 'ds_warehouse_transfer_inbound_order_receiving_tasks_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_inbound_order_receiving_tasks_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_inbound_orderlines_grid',
      name: 'ds_warehouse_transfer_inbound_orderlines_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_inbound_orderlines_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_outbound_order_editor',
      name: 'ds_warehouse_transfer_outbound_order_editor' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_outbound_order_editor',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_outbound_order_lines_grid',
      name: 'ds_warehouse_transfer_outbound_order_lines_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_outbound_order_lines_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_outbound_order_manual_allocations_grid',
      name: 'ds_warehouse_transfer_outbound_order_manual_allocations_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_outbound_order_manual_allocations_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_outbound_order_shippingcontents_grid',
      name: 'ds_warehouse_transfer_outbound_order_shippingcontents_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_outbound_order_shippingcontents_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfer_picking_grid',
      name: 'ds_warehouse_transfer_picking_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfer_picking_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'waveId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouse_transfers_grid',
      name: 'ds_warehouse_transfers_grid' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehouse_transfers_grid',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehousetransfer_statuses_dd',
      name: 'ds_warehousetransfer_statuses_dd' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehousetransfer_statuses_dd',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehousetransfer_types_dd',
      name: 'ds_warehousetransfer_types_dd' + ' ('+ 'WarehouseTransfers' +')',
      referenceName: 'ds_warehousetransfer_types_dd',
      appReferenceName: 'WarehouseTransfers',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
  ];

  async configDisplayWithFn(value: any): Promise<string> {
    return this.configList.find(item => item.key === value)?.name;
  }

  async configOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.configList.filter(item => item.type === this.typeValue);
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  configValueChange(value) {
    this.configValue = value;
    this.config = this.configList.find(item => item.key === value);
    this.methodValueChange(null);
    this.dd_method.clearListData();
  }

  get hasMethods():boolean {
    return this.config && this.config?.methods;
  }
  
  method = null;
  methodValue = null;

  async methodDisplayWithFn(value: any): Promise<string> {
    return value;
  }

  async methodOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.config.methods;
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  methodValueChange(value) {
    this.methodValue = value;
    this.result = null;    
    if (this.hasMethods) {
      this.method = this.config.methods.find(item => item.key === value);
    } else {
      this.method = null;
    }
    this.onInParamChanged();
  }


  get inParams() {
    if (this.config) {
      if (this.config.methods) {
        if (this.method) {
          return this.method.inParams;
        }
      } else {
        return this.config.inParams;
      }
    }
    return null;
  }

  previewInParams = null;
  onInParamChanged() {
    this.previewInParams = {};
    if (this.inParams && this.inParams.length) {
      const inParams: { [key: string]: any } = {};
      this.inParams.forEach(ip => {
        inParams[ip.id] = this.evaluateValue(ip.value);
      });
      this.previewInParams = inParams;
    }
  }

  evaluateValue(value: string) {
    const val = this.utils.isDefinedTrimmed(value) ? `(${value})` : value;
    const f = new Function(`{ return ${val} }`);
    return f();
  }

  get canExecute (): boolean {
    if (!this.config) {
      return false;
    }
    if (this.config.type === 6 && !this.methodValue) {
      return false;
    } 
    return true;
  }

  result: any;
  async run() {
    if (this.config.type === 6) {
      this.result = await this.datasources[this.config.appReferenceName][this.config.referenceName][this.methodValue](this.previewInParams);
    } else if (this.config.type === 9) {
      this.result = await this.flows[this.config.appReferenceName][this.config.referenceName](this.previewInParams);
    }
  }  
}
