import { Inject, Injectable, Injector } from '@angular/core';


import { Returns_add_licenseplates_from_outbound_to_inbound_order_flowService } from './Returns.flow.index';
import { Returns_auto_receive_return_order_flowService } from './Returns.flow.index';
import { Returns_cancel_return_order_flowService } from './Returns.flow.index';
import { Returns_complete_return_order_flowService } from './Returns.flow.index';
import { Returns_confirm_blind_container_flowService } from './Returns.flow.index';
import { Returns_create_blind_return_order_flowService } from './Returns.flow.index';
import { Returns_create_blind_return_session_task_flowService } from './Returns.flow.index';
import { Returns_create_licenseplate_flowService } from './Returns.flow.index';
import { Returns_create_or_update_return_task_flowService } from './Returns.flow.index';
import { Returns_create_return_order_from_outbound_flowService } from './Returns.flow.index';
import { Returns_delete_return_order_flowService } from './Returns.flow.index';
import { Returns_delete_return_task_flowService } from './Returns.flow.index';
import { Returns_excel_export_flowService } from './Returns.flow.index';
import { Returns_get_blind_return_lot_flowService } from './Returns.flow.index';
import { Returns_get_blind_return_new_licenseplate_statusService } from './Returns.flow.index';
import { Returns_get_blind_return_new_lot_statusService } from './Returns.flow.index';
import { Returns_get_return_orders_state_flowService } from './Returns.flow.index';
import { Returns_process_return_order_flowService } from './Returns.flow.index';
import { Returns_revert_return_order_flowService } from './Returns.flow.index';
import { Returns_unreceive_return_order_flowService } from './Returns.flow.index';
import { Returns_validate_return_order_flowService } from './Returns.flow.index';
import { Returns_validate_rga_number_flowService } from './Returns.flow.index';

import { $types } from './Returns.types'

@Injectable({ providedIn: 'root' })
export class Returns_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Returns: Returns_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _add_licenseplates_from_outbound_to_inbound_order_flow: Returns_add_licenseplates_from_outbound_to_inbound_order_flowService;
  public async add_licenseplates_from_outbound_to_inbound_order_flow(inParams: { warehouseTransferId: number }): Promise< { reasons?: string[] }> {
    if(!this._add_licenseplates_from_outbound_to_inbound_order_flow) {
      this._add_licenseplates_from_outbound_to_inbound_order_flow = this.injector.get(Returns_add_licenseplates_from_outbound_to_inbound_order_flowService);
    }
    return this._add_licenseplates_from_outbound_to_inbound_order_flow.run(inParams);
  }
  private _auto_receive_return_order_flow: Returns_auto_receive_return_order_flowService;
  public async auto_receive_return_order_flow(inParams: { orderId: number, noCommit?: boolean }): Promise< { reasons?: string[], reflectedOrderId?: number, confirmedLicensePlates?: number, confirmedPackagedAmount?: number, entities?: { set?: string, method?: string, keys?: { name?: string, value?: any }[], properties?: any }[] }> {
    if(!this._auto_receive_return_order_flow) {
      this._auto_receive_return_order_flow = this.injector.get(Returns_auto_receive_return_order_flowService);
    }
    return this._auto_receive_return_order_flow.run(inParams);
  }
  private _cancel_return_order_flow: Returns_cancel_return_order_flowService;
  public async cancel_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._cancel_return_order_flow) {
      this._cancel_return_order_flow = this.injector.get(Returns_cancel_return_order_flowService);
    }
    return this._cancel_return_order_flow.run(inParams);
  }
  private _complete_return_order_flow: Returns_complete_return_order_flowService;
  public async complete_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._complete_return_order_flow) {
      this._complete_return_order_flow = this.injector.get(Returns_complete_return_order_flowService);
    }
    return this._complete_return_order_flow.run(inParams);
  }
  private _confirm_blind_container_flow: Returns_confirm_blind_container_flowService;
  public async confirm_blind_container_flow(inParams: { shippingContainerId: number, isAutoReceive?: boolean }): Promise< { reasons?: string[] }> {
    if(!this._confirm_blind_container_flow) {
      this._confirm_blind_container_flow = this.injector.get(Returns_confirm_blind_container_flowService);
    }
    return this._confirm_blind_container_flow.run(inParams);
  }
  private _create_blind_return_order_flow: Returns_create_blind_return_order_flowService;
  public async create_blind_return_order_flow(inParams: { waitStatus?: string, lookupcode?: string, orderClassId: number, projectId: number, warehouseId: number, ownerReference?: string }): Promise< { reason?: string, orderId?: number, shipmentId?: number }> {
    if(!this._create_blind_return_order_flow) {
      this._create_blind_return_order_flow = this.injector.get(Returns_create_blind_return_order_flowService);
    }
    return this._create_blind_return_order_flow.run(inParams);
  }
  private _create_blind_return_session_task_flow: Returns_create_blind_return_session_task_flowService;
  public async create_blind_return_session_task_flow(inParams: { shippingContainerId: number, warehouseId: number }): Promise< { taskId?: number, reasons?: string[] }> {
    if(!this._create_blind_return_session_task_flow) {
      this._create_blind_return_session_task_flow = this.injector.get(Returns_create_blind_return_session_task_flowService);
    }
    return this._create_blind_return_session_task_flow.run(inParams);
  }
  private _create_licenseplate_flow: Returns_create_licenseplate_flowService;
  public async create_licenseplate_flow(inParams: { properties: any }): Promise< { reasons?: string[], licenseplateId?: number }> {
    if(!this._create_licenseplate_flow) {
      this._create_licenseplate_flow = this.injector.get(Returns_create_licenseplate_flowService);
    }
    return this._create_licenseplate_flow.run(inParams);
  }
  private _create_or_update_return_task_flow: Returns_create_or_update_return_task_flowService;
  public async create_or_update_return_task_flow(inParams: { projectId: number, materialId: number, vendorLotId: number, lotId: number, packagingId: number, packagedAmount: number, warehouseId: number, chainHead: number, otherProperties?: any, sourceLicensePlateId?: number, targetLicensePlateId: number, serialNumberId?: number, sourceShippingContainerId: number, taskId?: number }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._create_or_update_return_task_flow) {
      this._create_or_update_return_task_flow = this.injector.get(Returns_create_or_update_return_task_flowService);
    }
    return this._create_or_update_return_task_flow.run(inParams);
  }
  private _create_return_order_from_outbound_flow: Returns_create_return_order_from_outbound_flowService;
  public async create_return_order_from_outbound_flow(inParams: { outboundOrderId: number, inboundOrder?: { lookupcode?: string, requestedDeliveryDate?: string, ownerReference?: string, warehouseId?: number } }): Promise< { warehouseTransferId?: number, outboundOrderId?: number, inboundOrderId?: number, outboundShipmentId?: number, inboundShipmentId?: number, reasons?: string[] }> {
    if(!this._create_return_order_from_outbound_flow) {
      this._create_return_order_from_outbound_flow = this.injector.get(Returns_create_return_order_from_outbound_flowService);
    }
    return this._create_return_order_from_outbound_flow.run(inParams);
  }
  private _delete_return_order_flow: Returns_delete_return_order_flowService;
  public async delete_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._delete_return_order_flow) {
      this._delete_return_order_flow = this.injector.get(Returns_delete_return_order_flowService);
    }
    return this._delete_return_order_flow.run(inParams);
  }
  private _delete_return_task_flow: Returns_delete_return_task_flowService;
  public async delete_return_task_flow(inParams: { taskId: number }): Promise< { reasons?: string[], debug?: string }> {
    if(!this._delete_return_task_flow) {
      this._delete_return_task_flow = this.injector.get(Returns_delete_return_task_flowService);
    }
    return this._delete_return_task_flow.run(inParams);
  }
  private _excel_export_flow: Returns_excel_export_flowService;
  public async excel_export_flow(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string, carrierId?: number, carrierServiceTypeId?: number, materialIds?: number[], fullTextSearch?: string, reference?: string, orderIds?: number[], exportLimit?: number, exportType?: string, dateType?: string, dateFormat?: string, totalRecords?: number }): Promise< { transactions?: any, logMessage?: string[] }> {
    if(!this._excel_export_flow) {
      this._excel_export_flow = this.injector.get(Returns_excel_export_flowService);
    }
    return this._excel_export_flow.run(inParams);
  }
  private _get_blind_return_lot_flow: Returns_get_blind_return_lot_flowService;
  public async get_blind_return_lot_flow(inParams: { vendorLotId: number, vendorLotCode: string, materialId: number, lotCode?: string, expirationDate?: string, shippingContainerId?: number }): Promise< { reasons?: string[], lot?: { id?: number, lookupcode?: string, statusId?: number } }> {
    if(!this._get_blind_return_lot_flow) {
      this._get_blind_return_lot_flow = this.injector.get(Returns_get_blind_return_lot_flowService);
    }
    return this._get_blind_return_lot_flow.run(inParams);
  }
  private _get_blind_return_new_licenseplate_status: Returns_get_blind_return_new_licenseplate_statusService;
  public async get_blind_return_new_licenseplate_status(inParams: { manufactureDate?: string, expirationDate?: string, lookupcode?: string, otherProperties?: any, lotStatusId?: number }): Promise< { recommendedStatus?: { id?: number, name?: string }, reasons?: string[], isMandatory?: boolean }> {
    if(!this._get_blind_return_new_licenseplate_status) {
      this._get_blind_return_new_licenseplate_status = this.injector.get(Returns_get_blind_return_new_licenseplate_statusService);
    }
    return this._get_blind_return_new_licenseplate_status.run(inParams);
  }
  private _get_blind_return_new_lot_status: Returns_get_blind_return_new_lot_statusService;
  public async get_blind_return_new_lot_status(inParams: { manufactureDate?: string, expirationDate?: string, lookupcode?: string, materialId?: number }): Promise< { recommendedStatusId?: number, reasons?: string[], isMandatory?: boolean }> {
    if(!this._get_blind_return_new_lot_status) {
      this._get_blind_return_new_lot_status = this.injector.get(Returns_get_blind_return_new_lot_statusService);
    }
    return this._get_blind_return_new_lot_status.run(inParams);
  }
  private _get_return_orders_state_flow: Returns_get_return_orders_state_flowService;
  public async get_return_orders_state_flow(inParams: { orderIds: number[] }): Promise< { reasons?: string[], orderStates?: { orderId?: number, failActionReasons?: { cancelReasons?: string[], completeReasons?: string[], deleteReasons?: string[], revertReasons?: string[], processReasons?: string[], unreceiveReasons?: string[], autoReceiveReasons?: string[], validateReasons?: string[] } }[] }> {
    if(!this._get_return_orders_state_flow) {
      this._get_return_orders_state_flow = this.injector.get(Returns_get_return_orders_state_flowService);
    }
    return this._get_return_orders_state_flow.run(inParams);
  }
  private _process_return_order_flow: Returns_process_return_order_flowService;
  public async process_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._process_return_order_flow) {
      this._process_return_order_flow = this.injector.get(Returns_process_return_order_flowService);
    }
    return this._process_return_order_flow.run(inParams);
  }
  private _revert_return_order_flow: Returns_revert_return_order_flowService;
  public async revert_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._revert_return_order_flow) {
      this._revert_return_order_flow = this.injector.get(Returns_revert_return_order_flowService);
    }
    return this._revert_return_order_flow.run(inParams);
  }
  private _unreceive_return_order_flow: Returns_unreceive_return_order_flowService;
  public async unreceive_return_order_flow(inParams: { orderId: number, reasonCodeId?: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._unreceive_return_order_flow) {
      this._unreceive_return_order_flow = this.injector.get(Returns_unreceive_return_order_flowService);
    }
    return this._unreceive_return_order_flow.run(inParams);
  }
  private _validate_return_order_flow: Returns_validate_return_order_flowService;
  public async validate_return_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[], reflectedOrderId?: number }> {
    if(!this._validate_return_order_flow) {
      this._validate_return_order_flow = this.injector.get(Returns_validate_return_order_flowService);
    }
    return this._validate_return_order_flow.run(inParams);
  }
  private _validate_rga_number_flow: Returns_validate_rga_number_flowService;
  public async validate_rga_number_flow(inParams: { rgaNumber: string, orderId: number, otherProperties?: any }): Promise< { reasons?: string[] }> {
    if(!this._validate_rga_number_flow) {
      this._validate_rga_number_flow = this.injector.get(Returns_validate_rga_number_flowService);
    }
    return this._validate_rga_number_flow.run(inParams);
  }
}
