import { Inject, Injectable, Injector } from '@angular/core';


import { PackVerification_master_packing_list_reportService } from './PackVerification.report.index';
import { PackVerification_packing_list_reportService } from './PackVerification.report.index';
import { PackVerification_shipping_label_reportService } from './PackVerification.report.index';

@Injectable({ providedIn: 'root' })
export class PackVerification_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public PackVerification: PackVerification_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _master_packing_list_report: PackVerification_master_packing_list_reportService;
  public get master_packing_list_report(): PackVerification_master_packing_list_reportService {
    if(!this._master_packing_list_report) {
      this._master_packing_list_report = this.injector.get(PackVerification_master_packing_list_reportService);
    }
    return this._master_packing_list_report;
  }
  private _packing_list_report: PackVerification_packing_list_reportService;
  public get packing_list_report(): PackVerification_packing_list_reportService {
    if(!this._packing_list_report) {
      this._packing_list_report = this.injector.get(PackVerification_packing_list_reportService);
    }
    return this._packing_list_report;
  }
  private _shipping_label_report: PackVerification_shipping_label_reportService;
  public get shipping_label_report(): PackVerification_shipping_label_reportService {
    if(!this._shipping_label_report) {
      this._shipping_label_report = this.injector.get(PackVerification_shipping_label_reportService);
    }
    return this._shipping_label_report;
  }
}

