import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_appointment_badge_reportService } from './Reports.report.index';
import { Reports_custom_license_plate_report_by_serialIdService } from './Reports.report.index';
import { Reports_custom_outbound_labels_reportService } from './Reports.report.index';
import { Reports_custom_outbound_packing_slip_by_shipmentIdService } from './Reports.report.index';
import { Reports_custom_outbound_packing_slip_by_shipmentId_TESTService } from './Reports.report.index';
import { Reports_custom_outbound_packing_slip_by_shippingContainerIdService } from './Reports.report.index';
import { Reports_custom_receiving_reportService } from './Reports.report.index';
import { Reports_custom_transaction_reportService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_appointment_badge_report: Reports_custom_appointment_badge_reportService;
  public get custom_appointment_badge_report(): Reports_custom_appointment_badge_reportService {
    if(!this._custom_appointment_badge_report) {
      this._custom_appointment_badge_report = this.injector.get(Reports_custom_appointment_badge_reportService);
    }
    return this._custom_appointment_badge_report;
  }
  private _custom_license_plate_report_by_serialId: Reports_custom_license_plate_report_by_serialIdService;
  public get custom_license_plate_report_by_serialId(): Reports_custom_license_plate_report_by_serialIdService {
    if(!this._custom_license_plate_report_by_serialId) {
      this._custom_license_plate_report_by_serialId = this.injector.get(Reports_custom_license_plate_report_by_serialIdService);
    }
    return this._custom_license_plate_report_by_serialId;
  }
  private _custom_outbound_labels_report: Reports_custom_outbound_labels_reportService;
  public get custom_outbound_labels_report(): Reports_custom_outbound_labels_reportService {
    if(!this._custom_outbound_labels_report) {
      this._custom_outbound_labels_report = this.injector.get(Reports_custom_outbound_labels_reportService);
    }
    return this._custom_outbound_labels_report;
  }
  private _custom_outbound_packing_slip_by_shipmentId: Reports_custom_outbound_packing_slip_by_shipmentIdService;
  public get custom_outbound_packing_slip_by_shipmentId(): Reports_custom_outbound_packing_slip_by_shipmentIdService {
    if(!this._custom_outbound_packing_slip_by_shipmentId) {
      this._custom_outbound_packing_slip_by_shipmentId = this.injector.get(Reports_custom_outbound_packing_slip_by_shipmentIdService);
    }
    return this._custom_outbound_packing_slip_by_shipmentId;
  }
  private _custom_outbound_packing_slip_by_shipmentId_TEST: Reports_custom_outbound_packing_slip_by_shipmentId_TESTService;
  public get custom_outbound_packing_slip_by_shipmentId_TEST(): Reports_custom_outbound_packing_slip_by_shipmentId_TESTService {
    if(!this._custom_outbound_packing_slip_by_shipmentId_TEST) {
      this._custom_outbound_packing_slip_by_shipmentId_TEST = this.injector.get(Reports_custom_outbound_packing_slip_by_shipmentId_TESTService);
    }
    return this._custom_outbound_packing_slip_by_shipmentId_TEST;
  }
  private _custom_outbound_packing_slip_by_shippingContainerId: Reports_custom_outbound_packing_slip_by_shippingContainerIdService;
  public get custom_outbound_packing_slip_by_shippingContainerId(): Reports_custom_outbound_packing_slip_by_shippingContainerIdService {
    if(!this._custom_outbound_packing_slip_by_shippingContainerId) {
      this._custom_outbound_packing_slip_by_shippingContainerId = this.injector.get(Reports_custom_outbound_packing_slip_by_shippingContainerIdService);
    }
    return this._custom_outbound_packing_slip_by_shippingContainerId;
  }
  private _custom_receiving_report: Reports_custom_receiving_reportService;
  public get custom_receiving_report(): Reports_custom_receiving_reportService {
    if(!this._custom_receiving_report) {
      this._custom_receiving_report = this.injector.get(Reports_custom_receiving_reportService);
    }
    return this._custom_receiving_report;
  }
  private _custom_transaction_report: Reports_custom_transaction_reportService;
  public get custom_transaction_report(): Reports_custom_transaction_reportService {
    if(!this._custom_transaction_report) {
      this._custom_transaction_report = this.injector.get(Reports_custom_transaction_reportService);
    }
    return this._custom_transaction_report;
  }
}

